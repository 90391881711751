import { useMemo } from 'react';

import { AUTH_ROUTES, MAIN_ROUTES } from '@router/index';
import { ANY, MAIN } from '@router/consts';
import { AuthLayout, MainLayout } from '@layouts/index';
import { RedirectPage, Loading } from '@pages/shared';

export const useRouterPaths = (isInitializing, user) =>
  useMemo(() => {
    if (isInitializing) {
      return [{ path: ANY, element: <Loading /> }];
    }

    if (user) {
      const layout = <MainLayout />;
      const routes = [
        ...MAIN_ROUTES,
        {
          path: ANY,
          element: <RedirectPage path={MAIN.CLIENTS.PATH} />,
        },
      ];

      return [{ element: layout, children: routes }];
    }

    const layout = <AuthLayout />;
    const routes = [
      ...AUTH_ROUTES,
      {
        path: ANY,
        element: <RedirectPage />,
      },
    ];

    return [{ element: layout, children: routes }];
  }, [isInitializing, user]);
