import { useRoutes } from 'react-router-dom';

import { useRouterPaths } from '@hooks/routerPaths';
import { useUser } from '@contexts/User';

import './styles/main.scss';

const App = () => {
  const { isInitializing, user } = useUser();

  const routers = useRouterPaths(isInitializing, user);
  const routes = useRoutes(routers);

  return routers && routes;
};

export default App;
