import PropTypes from 'prop-types';

const EyeCrossed = ({ color, height, width, ...props }) => (
  <svg
    viewBox="0 0 23 23"
    height={height}
    width={width}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.385 8.797a13.55 13.55 0 0 0-.882-1.236.959.959 0 0 0-1.428-.086L15.2 10.35c.211.632.25 1.36.058 2.118a3.854 3.854 0 0 1-2.78 2.779 3.758 3.758 0 0 1-2.117-.058l-2.358 2.358c-.479.479-.326 1.322.317 1.572a8.837 8.837 0 0 0 3.181.594c1.706 0 3.364-.499 4.878-1.428 1.543-.959 2.933-2.367 4.054-4.16.91-1.447.862-3.88-.048-5.328ZM13.434 9.564l-3.871 3.871a2.781 2.781 0 0 1-.805-1.935 2.746 2.746 0 0 1 2.74-2.741c.748 0 1.438.316 1.937.805Z"
      fill={color}
    />
    <path
      d="M20.863 2.138a.74.74 0 0 0-1.044 0L2.138 19.828a.74.74 0 0 0 0 1.045.726.726 0 0 0 1.035-.01l17.69-17.69a.712.712 0 0 0 0-1.035Z"
      fill={color}
    />
    <path
      d="m17.49 5.51-3.248 3.25a3.82 3.82 0 0 0-2.74-1.132A3.867 3.867 0 0 0 7.63 11.5a3.86 3.86 0 0 0 1.13 2.74l-3.239 3.25h-.01c-1.063-.863-2.04-1.965-2.874-3.268-.959-1.505-.959-3.949 0-5.453 1.111-1.744 2.472-3.115 3.986-4.054 1.515-.92 3.172-1.428 4.878-1.428 2.137 0 4.207.786 5.99 2.223ZM14.24 11.5a2.746 2.746 0 0 1-2.741 2.74c-.058 0-.106 0-.163-.018l2.884-2.885c.02.057.02.105.02.163Z"
      fill={color}
    />
  </svg>
);

EyeCrossed.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

EyeCrossed.defaultProps = {
  color: 'var(--colors-tundora)',
  height: '1.575rem',
  width: '1.575rem',
};

export default EyeCrossed;
