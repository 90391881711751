import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ROOT } from '@router/consts';

const RedirectPage = ({ path }) => <Navigate to={path} replace />;

RedirectPage.propTypes = {
  path: PropTypes.string,
};

RedirectPage.defaultProps = {
  path: ROOT,
};

export default RedirectPage;
