import PropTypes from 'prop-types';

const Profile = ({ color, height, width, ...props }) => (
  <svg
    viewBox="0 0 34 43"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.005 0C10.77 0 5.715 5.076 5.715 11.337c0 6.262 5.055 11.337 11.29 11.337 6.235 0 11.289-5.075 11.289-11.337C28.294 5.076 23.24 0 17.004 0Zm-.002 3.077c4.543 0 8.226 3.698 8.226 8.26 0 4.563-3.683 8.262-8.226 8.262-4.543 0-8.226-3.699-8.226-8.261 0-4.563 3.683-8.261 8.226-8.261Zm-4.614 24.167c-1.653.115-3.323.35-4.971.704-3.18.655-5.725 1.964-6.808 4.139a6.226 6.226 0 0 0-.014 5.345c1.04 2.15 3.288 3.367 6.324 4.041l.543.114c1.603.362 3.274.605 4.956.72.144.041.485.08.858.1l.307.011c.157.004.336.005.603.005 2.417.133 4.924.094 7.419-.119 1.33-.09 2.668-.264 3.995-.52l.994-.205c3.277-.647 5.732-1.877 6.807-4.144a6.218 6.218 0 0 0 0-5.32c-1.072-2.263-3.495-3.482-6.832-4.17-1.31-.28-2.64-.486-3.98-.618l-.98-.083a52.46 52.46 0 0 0-9.221 0Zm8.951 3.065.027.002c1.538.108 3.066.324 4.574.646 2.479.51 4.161 1.357 4.693 2.48.4.846.4 1.829 0 2.676-.497 1.049-2.008 1.859-4.192 2.354l-.478.101c-1.537.343-3.06.564-4.594.67-2.38.202-4.744.238-7.103.11l-.837-.014a4.132 4.132 0 0 1-.609-.07 33.021 33.021 0 0 1-3.983-.529l-.749-.158c-2.486-.488-4.183-1.338-4.733-2.475a3.133 3.133 0 0 1-.295-1.335c-.002-.464.1-.914.297-1.324.535-1.074 2.333-2 4.685-2.484a32.682 32.682 0 0 1 4.584-.648 49.531 49.531 0 0 1 8.713-.002Z"
      fill={color}
    />
  </svg>
);

Profile.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Profile.defaultProps = {
  color: 'var(--colors-tundora)',
  height: '2.6875rem',
  width: '2.125rem',
};

export default Profile;
