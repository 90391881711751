import PropTypes from 'prop-types';

const Info = ({ height, width, color, ...props }) => (
  <svg
    viewBox="0 0 2 9"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1 1.042v4.166" stroke={color} strokeWidth={2} strokeLinecap="round" />
    <path d="M1 9a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" fill={color} />
  </svg>
);

Info.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Info.defaultProps = {
  color: 'var(--colors-tundora)',
  height: '0.563rem',
  width: '0.125rem',
};

export default Info;
