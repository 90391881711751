import config from '@config/index';

import ApiService from './api';

class ProfileApi extends ApiService {
  constructor() {
    if (ProfileApi.instance) {
      // eslint-disable-next-line no-constructor-return
      return ProfileApi.instance;
    }

    super(config.accountApiUrl);
    ProfileApi.instance = this;

    this.getProfilesByUserId = this.getProfilesByUserId.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.getAll = this.getAll.bind(this);
  }

  async getProfilesByUserId(userId) {
    const response = await this.http.get(`/persons?userId=${userId}`);
    return response.data;
  }

  async getAll() {
    const response = await this.http.get('/persons');
    return response.data;
  }

  async updateProfile(data) {
    const response = await this.http.put(`/persons/${data.id}`, data);
    return response.data;
  }
}

const profileApiInstance = new ProfileApi();
export default profileApiInstance;
