import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';

import AuthService from '@services/api/auth';
import ProfileService from '@services/api/profile';
import StorageService from '@services/storage';
import UserContextRef from '@contexts/UserRef';
import { USER_TYPES } from '@utils/consts';

export const UserContext = createContext({
  user: null,
  profile: null,
  updateCurrentProfile: async () => {},
  login: async () => {},
  setUserContextData: async () => {},
  logout: async () => {},
  setIsInitializing: () => {},
  isInitializing: false,
});

const UserProvider = ({ children }) => {
  const queryClient = useQueryClient();

  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isInitializing, setIsInitializing] = useState(true);

  const innerRef = useRef(UserContextRef.getInstance());

  const resetUserContextData = useCallback(() => {
    // Order is very important
    StorageService.removeRefreshToken();
    StorageService.removeAccessToken();

    queryClient.clear();

    setUser(null);
    setProfile(null);
  }, [queryClient]);

  // removing access and refresh token is performed inside response interceptor for 401 error, however we should
  // remove tokens manually in catch block to cover logical validations and unpredictable network errors
  const setUserContextData = useCallback(async () => {
    const userData = await AuthService.getCurrentUser();

    const [profileData] = await ProfileService.getProfilesByUserId(userData.id);
    if (!profileData) {
      throw new Error(`No profile found by userId ${userData.id}`);
    }

    if (profileData.type !== USER_TYPES.COACH) {
      throw new Error('Invalid user type. Only coaches allowed');
    }

    setProfile(profileData);
    setUser(userData);
  }, []);

  useEffect(() => {
    innerRef.current.isReady = true;
    innerRef.current.reset = resetUserContextData;
  }, [resetUserContextData]);

  useEffect(() => {
    const fetchData = async (ignoreRefreshProcessing) => {
      setIsInitializing(true);

      const accessToken = StorageService.getAccessToken();
      const refreshToken = StorageService.getRefreshToken();

      // TODO Investigate: manual delete of accessToken (only access, not refresh) from another tab (using dev tools)
      // causes unexpected behavior (multiple refreshes + requests with old and new tokens, which results
      // no states with no token values on storage or states with tokens in storage),
      // so to handle this behavior we are fetching data only when accessToken exists (manual update access token to
      // invalid value still causes unexpected behavior)
      if (!accessToken && (ignoreRefreshProcessing || !refreshToken)) {
        resetUserContextData();

        setIsInitializing(false);
        return;
      }

      try {
        await setUserContextData();
      } catch {
        StorageService.removeRefreshToken();
        StorageService.removeAccessToken();
      } finally {
        setIsInitializing(false);
      }
    };

    fetchData();

    // This is needed to track local storage changes from other browser tab
    // (changes from the tab listener was created in are not triggering event)
    // TODO Investigate: manual delete of accessToken (only access, not refresh) from another tab (using dev tools)
    // causes unexpected behavior (multiple refreshes + requests with old and new tokens, which results
    // no states with no token values on storage or states with tokens in storage),
    // so to handle this behavior we are fetching data only when accessToken exists (manual update access token to
    // invalid value still causes unexpected behavior)
    const storageHandler = (e) => {
      // local storage key
      if (e.key === 'accessToken') {
        fetchData(true);
      }
    };

    window.addEventListener('storage', storageHandler);

    return () => {
      window.removeEventListener('storage', storageHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUserContextData]);

  const updateCurrentProfile = useCallback(
    async (updatedProfile) => {
      if (!profile || profile.id !== updatedProfile.id) {
        throw new Error('You can not update another person profile');
      }

      const result = await ProfileService.updateProfile(updatedProfile);
      setProfile(result);
    },
    [profile]
  );

  const login = useCallback(
    async ({ email, password }) => {
      const tokens = await AuthService.emailLogin({ email, password });
      StorageService.setRefreshToken(tokens.refreshToken);
      StorageService.setAccessToken(tokens.accessToken);

      try {
        await setUserContextData();
      } catch {
        StorageService.removeRefreshToken();
        StorageService.removeAccessToken();
      }
    },
    [setUserContextData]
  );

  const logout = useCallback(async () => {
    // TODO: try to remove await logout by passing tokens manually (storage might clean before request is performed)
    await AuthService.logout();
    resetUserContextData();
  }, [resetUserContextData]);

  const value = useMemo(
    () => ({
      user,
      profile,
      updateCurrentProfile,
      login,
      logout,
      isInitializing,
      setIsInitializing,
      setUserContextData,
    }),
    [
      user,
      profile,
      updateCurrentProfile,
      login,
      logout,
      isInitializing,
      setIsInitializing,
      setUserContextData,
    ]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUser = () => {
  const value = useContext(UserContext);
  return value;
};

export default React.memo(UserProvider);
