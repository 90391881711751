import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import UserProvider from '@contexts/User';
import { ToastContainer, ResponsiveFontSizeContainer } from '@components/index';
import './i18n/i18n';
import App from './App';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ResponsiveFontSizeContainer>
      <QueryClientProvider client={queryClient}>
        <SkeletonTheme highlightColor="#FDFBF8" baseColor="#F4EEE6">
          <UserProvider>
            <ToastContainer />
            <App />
          </UserProvider>
        </SkeletonTheme>
      </QueryClientProvider>
    </ResponsiveFontSizeContainer>
  </BrowserRouter>
);
