import { Outlet } from 'react-router-dom';

import { Logo } from '@assets/illustrations';

const AuthLayout = () => {
  return (
    <div className="h-full max-h-full flex relative bg-main items-center justify-center">
      <Logo className="absolute left-8 top-8 z-10" />
      <Outlet />
    </div>
  );
};

export default AuthLayout;
