import PropTypes from 'prop-types';

const Clock = ({ height, width, color, ...props }) => (
  <svg
    viewBox="0 0 23 23"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.83 6.635V11.5l3.243 3.243"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.83 11.5c0-5.523-4.477-10-10-10s-10 4.477-10 10 4.477 10 10 10 10-4.477 10-10Z"
      stroke={color}
      strokeWidth={1.5}
    />
  </svg>
);

Clock.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Clock.defaultProps = {
  color: 'var(--colors-tundora)',
  height: '1.4375rem',
  width: '1.4375rem',
};

export default Clock;
