import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import PropTypes from 'prop-types';

const LoadableFallBack = ({ withBorderRadius }) => {
  return (
    <div className="h-full w-full" style={{ lineHeight: 0 }}>
      <Skeleton
        borderRadius={withBorderRadius ? 2 : 0}
        containerClassName="h-full w-full"
        style={{
          width: '100%',
          height: '100%',
        }}
      />
    </div>
  );
};

LoadableFallBack.propTypes = {
  withBorderRadius: PropTypes.bool,
};

LoadableFallBack.defaultProps = {
  withBorderRadius: false,
};

export default LoadableFallBack;
