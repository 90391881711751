import PropTypes from 'prop-types';

const Assessment = ({ height, width, color, ...props }) => (
  <svg
    viewBox="0 0 26 26"
    height={height}
    width={width}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M13.215 9.25h5.469M6.973 9.25l.78.781 2.345-2.344M13.215 16.542h5.469M6.973 16.542l.78.781 2.345-2.344"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M9.702 22.916h6.25c5.208 0 7.292-2.083 7.292-7.291v-6.25c0-5.209-2.084-7.292-7.292-7.292h-6.25c-5.209 0-7.292 2.083-7.292 7.292v6.25c0 5.208 2.083 7.291 7.292 7.291z"
    />
  </svg>
);

Assessment.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Assessment.defaultProps = {
  color: 'var(--colors-gray-0)',
  height: '1.625rem',
  width: '1.625rem',
};

export default Assessment;
