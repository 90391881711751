export const USER_TYPES = {
  COACH: 'coach',
  USER: 'user',
};

export const TIME = {
  millisecondsInHour: 3600000,
  millisecondsInDay: 86400000,
  millisecondsInMinute: 60000,
};

const JOURNEY_STEPS = {
  LEARN: 'learn',
  SENSE: 'sense',
  GROW: 'grow',
};

// max file size is around 7mb
export const PROFILE_PICTURE_SIZE_LIMIT = 7000000;

export const RESOURCE_TYPES = {
  PROFILE_PICTURE: 'profilePicture',
};

export const DOMAIN = {
  ENGAGEMENT_QUALITY_HOURS_LIMIT: 10,
  JOURNEY_STEPS,
  CALENDAR_EVENT_STATUSES: {
    ACTIVE: 'active',
    COMPLETED: 'completed',
  },
  SURVEY_STATUSES: {
    ACTIVE: 'active',
    COMPLETED: 'completed',
  },
  SURVEY_TYPES: {
    ASSESSMENT: 'assessment',
    REFLECTION: 'reflection',
    MORNING_REFLECTION: 'morningReflection',
  },
  ENTITY_TYPES: {
    JOURNEY_STEP: 'journeyStep',
    SURVEY: 'survey',
    JOURNEY: 'journey',
    EXERCISE: 'exercise',
  },
  JOURNEY_STEP_DAYS_AMOUNT_PREFERRED: 7,
  JOURNEY_STEP_ACTIONS_COMPLETION_PERCENTAGE: 0.8,
  REFLECTIONS_DAY_SCHEDULE: {
    [JOURNEY_STEPS.LEARN]: {
      morning: {
        dtStart: TIME.millisecondsInHour * 6,
        dtEnd: TIME.millisecondsInHour * 10 - 1,
      },
      noon: {
        dtStart: TIME.millisecondsInHour * 12,
        dtEnd: TIME.millisecondsInHour * 13.5 - 1,
      },
      afternoon: {
        dtStart: TIME.millisecondsInHour * 15,
        dtEnd: TIME.millisecondsInHour * 16.5 - 1,
      },
      evening: {
        dtStart: TIME.millisecondsInHour * 18,
        dtEnd: TIME.millisecondsInHour * 19.5 - 1,
      },
      night: {
        dtStart: TIME.millisecondsInHour * 21,
        dtEnd: TIME.millisecondsInHour * 22.5 - 1,
      },
    },
    [JOURNEY_STEPS.SENSE]: {
      morning: {
        dtStart: TIME.millisecondsInHour * 6,
        dtEnd: TIME.millisecondsInHour * 10 - 1,
      },
      noon: {
        dtStart: TIME.millisecondsInHour * 12,
        dtEnd: TIME.millisecondsInHour * 13.5 - 1,
      },
      afternoon: {
        dtStart: TIME.millisecondsInHour * 15,
        dtEnd: TIME.millisecondsInHour * 16.5 - 1,
      },
      evening: {
        dtStart: TIME.millisecondsInHour * 18,
        dtEnd: TIME.millisecondsInHour * 19.5 - 1,
      },
      night: {
        dtStart: TIME.millisecondsInHour * 21,
        dtEnd: TIME.millisecondsInHour * 22.5 - 1,
      },
    },
    [JOURNEY_STEPS.GROW]: {},
  },
  EXERCISE_DAY_SCHEDULE: {
    [JOURNEY_STEPS.LEARN]: {
      morning: {
        dtStart: TIME.millisecondsInHour * 7,
        dtEnd: TIME.millisecondsInHour * 24 - 1,
      },
      afternoon: {
        dtStart: TIME.millisecondsInHour * 13,
        dtEnd: TIME.millisecondsInHour * 24 - 1,
      },
      evening: {
        dtStart: TIME.millisecondsInHour * 18,
        dtEnd: TIME.millisecondsInHour * 24 - 1,
      },
    },
    [JOURNEY_STEPS.SENSE]: {
      morning: {
        dtStart: TIME.millisecondsInHour * 7,
        dtEnd: TIME.millisecondsInHour * 24 - 1,
      },
      afternoon1: {
        dtStart: TIME.millisecondsInHour * 13,
        dtEnd: TIME.millisecondsInHour * 24 - 1,
      },
      afternoon2: {
        dtStart: TIME.millisecondsInHour * 13,
        dtEnd: TIME.millisecondsInHour * 24 - 1,
      },
      afternoon3: {
        dtStart: TIME.millisecondsInHour * 13,
        dtEnd: TIME.millisecondsInHour * 24 - 1,
      },
      afternoon4: {
        dtStart: TIME.millisecondsInHour * 13,
        dtEnd: TIME.millisecondsInHour * 24 - 1,
      },
      evening: {
        dtStart: TIME.millisecondsInHour * 18,
        dtEnd: TIME.millisecondsInHour * 24 - 1,
      },
    },
    [JOURNEY_STEPS.GROW]: {},
  },
  INDICATORS: {
    MENTAL: 'mental',
    PHYSICAL: 'physical',
    PRODUCTIVITY: 'productivity',
  },
  INDICATORS_LAGGING_LIMIT_PERCENTAGE: 70,
  EXERCISE_STEP_GOALS: {
    PRODUCTIVITY: 'productivity',
    ATHLETIC_PERFORMANCE: 'athleticPerformance',
    STRESS_BALANCE: 'stressBalance',
    ANXIETY_RELIEF_BEGINNER: 'anxietyReliefBeginner',
    ANXIETY_RELIEF_ADVANCED: 'anxietyReliefAdvanced',
    BREATHING: 'breathing',
  },
  EXERCISE_BPM_VALUES: {
    BPM10: '10',
    BPM8: '8',
    BPM6: '6',
  },
  EXERCISE_GOALS: {
    LEARN: 'learn',
    ENERGY: 'energy',
    FOCUS: 'focus',
    CALM: 'calm',
  },
  EXERCISE_GOAL_EFFECTS: {
    NEGATIVE: 'negative',
    MODERATELY_NEGATIVE: 'moderatelyNegative',
    NEUTRAL: 'neutral',
    MODERATELY_POSITIVE: 'moderatelyPositive',
    POSITIVE: 'positive',
  },
};

export const BUTTON = {
  TYPES: {
    BUTTON: 'button',
    SUBMIT: 'submit',
  },
  VARIANTS: {
    DEFAULT: 'default',
  },
};

export const INPUT = {
  TYPES: {
    DEFAULT: 'text',
    PASSWORD: 'password',
  },
  STYLES: {
    DARK: 'dark',
    LIGHT: 'light',
  },
};

export const ACTIVITY_TYPE = {
  ASSESSMENT: 'assessment',
  REFLECTION: 'reflection',
  EXERCISE: 'exercise',
};

export const TOAST_TYPES = {
  INFO: 'info',
  ERROR: 'error',
  SUCCESS: 'success',
};

export const MEDIA_FONT_SIZE = {
  XS: 11,
  SM: 12,
  MD: 14,
  LG: 15,
  BASE: 16,
};

export const MEDIA_SCREEN = {
  XS: 1004, // Starting from here horizontal scroll appears
  SM: 1097,
  MD: 1281,
  LG: 1601,
  XL: 1901,
};
