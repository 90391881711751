const config = {
  organizationId: process.env.REACT_APP_ORGANIZATION_ID,
  organizationName: process.env.REACT_APP_ORGANIZATION_NAME,
  authApiUrl: process.env.REACT_APP_AUTH_API_URL,
  accountApiUrl: process.env.REACT_APP_ACCOUNT_API_URL,
  surveyApiUrl: process.env.REACT_APP_SURVEY_API_URL,
  exerciseApiUrl: process.env.REACT_APP_EXERCISE_API_URL,
  journeyApiUrl: process.env.REACT_APP_JOURNEY_API_URL,
  calendarApiUrl: process.env.REACT_APP_CALENDAR_API_URL,
  deviceMetricsApiUrl: process.env.REACT_APP_DEVICE_METRICS_API_URL,
  analyticsApiUrl: process.env.REACT_APP_ANALYTICS_API_URL,
  resourceApiUrl: process.env.REACT_APP_RESOURCE_API_URL,
};

export default config;
