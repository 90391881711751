import PropTypes from 'prop-types';

const AngleArrow = ({ color, height, width, ...props }) => (
  <svg
    viewBox="0 0 7 12"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.894.558a.625.625 0 0 1 .884 0l5 5a.625.625 0 0 1 0 .884l-5 5a.625.625 0 1 1-.884-.884L5.452 6 .894 1.442a.625.625 0 0 1 0-.884Z"
      fill={color}
    />
  </svg>
);

AngleArrow.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

AngleArrow.defaultProps = {
  color: 'var(--colors-tundora)',
  height: '0.75rem',
  width: '0.4375rem',
};

export default AngleArrow;
