import * as yup from 'yup';

yup.addMethod(yup.string, 'integer', function match(message) {
  return this.matches(/^\d+$/, message || 'The field should have digits only');
});

export const passwordRules = {
  minLength: {
    validate: (password) => password?.length > 7,
  },
  uppercase: {
    validate: (password) => /[A-Z]/.test(password),
  },
  lowercase: {
    validate: (password) => /[a-z]/.test(password),
  },
  number: {
    validate: (password) => /[0-9]/.test(password),
  },
};

export const emailValidation = ({ requiredError, formatError } = {}) =>
  yup.string().trim().required(requiredError).email(formatError);

export const passwordValidation = ({ requiredError, lengthError, formatError } = {}) =>
  yup
    .string()
    .required(requiredError)
    .min(8, lengthError)
    .test('password', formatError, (password) =>
      Object.values(passwordRules).every((rule) => rule.validate(password))
    );

export const loginSchema = ({ emailRequiredError, emailFormatError, passwordRequiredError }) =>
  yup.object({
    email: emailValidation({ requiredError: emailRequiredError, formatError: emailFormatError }),
    // Only required validation not to expose password format requirements during login
    password: yup.string().required(passwordRequiredError),
  });

export const emailSchema = ({ requiredError, formatError }) =>
  yup.object({
    email: emailValidation({ requiredError, formatError }),
  });

export const digitsCodeSchema = ({ requiredError, lengthError, digitsError }) =>
  yup.object({
    code: yup.string().trim().required(requiredError).length(6, lengthError).integer(digitsError),
  });

export const passwordResetSchema = ({
  passwordRequiredError,
  passwordLengthError,
  passwordFormatError,
  passwordRepeatMatchError,
}) =>
  yup.object({
    password: passwordValidation({
      requiredError: passwordRequiredError,
      lengthError: passwordLengthError,
      formatError: passwordFormatError,
    }),
    passwordRepeat: yup
      .string()
      .required(passwordRepeatMatchError)
      .oneOf([yup.ref('password')], passwordRepeatMatchError),
  });
