import loadable from '@loadable/component';

import { LoadableFallback } from '@components/index';

const Root = {
  SignIn: loadable(() => import('./SignIn'), {
    fallback: <LoadableFallback />,
  }),
  ChangePassword: loadable(() => import('./ChangePassword'), {
    fallback: <LoadableFallback />,
  }),
};

export default Root;
