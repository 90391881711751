import PropTypes from 'prop-types';

const Arrow = ({ color, width, height, ...props }) => (
  <svg
    viewBox="0 0 54 16"
    height={height}
    width={width}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M1 8h52M46 1l7 7-7 7"
    />
  </svg>
);

Arrow.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Arrow.defaultProps = {
  color: 'var(--colors-primary)',
  width: '3.25rem',
  height: '0.875rem',
};

export default Arrow;
