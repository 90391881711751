import PropTypes from 'prop-types';

const Folder = ({ color, height, width, ...props }) => (
  <svg
    viewBox="0 0 27 27"
    height={height}
    width={width}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24.651 12.38v6.722c0 4.481-1.12 5.602-5.601 5.602H7.846c-4.481 0-5.602-1.12-5.602-5.602V7.898c0-4.481 1.12-5.602 5.602-5.602h1.68c1.681 0 2.05.493 2.69 1.345l1.68 2.24c.426.56.672.897 1.793.897h3.36c4.482 0 5.602 1.12 5.602 5.602Z"
      stroke={color}
      strokeWidth={2.127}
      strokeMiterlimit={10}
    />
    <path
      d="M8.963 2.296h10.083c2.241 0 3.361 1.12 3.361 3.361v1.547"
      stroke={color}
      strokeWidth={2.127}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Folder.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Folder.defaultProps = {
  color: 'var(--colors-tundora)',
  height: '1.6875rem',
  width: '1.6875rem',
};

export default Folder;
