import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';

import AuthService from '@services/api/auth';
import { digitsCodeSchema } from '@utils/validators/auth';
import { useTimer } from '@hooks/timer';
import { InputControl, Button } from '@components/index';
import { INPUT, BUTTON, TOAST_TYPES } from '@utils/consts';
import { showToast } from '@utils/helpers';

const FORM_KEYS = {
  CODE: 'code',
};

const CodeVerification = ({ email, onSuccess }) => {
  const { t } = useTranslation();

  const { value: timerValue, startTimer, stopTimer } = useTimer();

  const {
    control,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      digitsCodeSchema({
        requiredError: t('common.codeRequiredError'),
        lengthError: t('common.codeLengthError'),
        digitsError: t('common.codeDigitsError'),
      })
    ),
    reValidateMode: 'onSubmit',
    shouldFocusError: false,
  });

  const { mutate: requestPasswordReset } = useMutation(AuthService.requestPasswordReset, {
    onError: () => {
      showToast(TOAST_TYPES.ERROR, t('auth.changePassword.resendCodeFailed'));
    },
  });

  const { mutate: verifyPasswordResetCode, isLoading } = useMutation(
    AuthService.verifyPasswordResetCode,
    {
      onError: () => {
        setError(FORM_KEYS.CODE, { message: t('auth.changePassword.wrongCode') });
      },
      onSuccess: (_, requestData) => {
        onSuccess({
          email: requestData.email,
          code: requestData.resetPasswordCode,
        });
      },
    }
  );

  useEffect(() => {
    if (timerValue !== null && timerValue <= 0) {
      stopTimer();
    }
  }, [timerValue, stopTimer]);

  const handleCodeVerify = (form) => {
    verifyPasswordResetCode({
      email,
      resetPasswordCode: form[FORM_KEYS.CODE],
    });
  };

  const handleResendCode = () => {
    requestPasswordReset({ email });
    startTimer(60, -1);
  };

  const handleInputChange = (fieldName) => () => {
    clearErrors(fieldName);
  };

  const resendTime = timerValue
    ? `${Math.floor(timerValue / 60)}:${Math.floor(timerValue % 60)
        .toString()
        .padStart(2, '0')}`
    : null;

  return (
    <form
      onSubmit={handleSubmit(handleCodeVerify)}
      className={clsx('flex flex-col items-center', isLoading && 'pointer-events-none')}
    >
      <InputControl
        name={FORM_KEYS.CODE}
        control={control}
        onChange={handleInputChange(FORM_KEYS.CODE)}
        error={errors[FORM_KEYS.CODE]?.message}
        placeholder={t('auth.changePassword.enterCode')}
        style={INPUT.STYLES.DARK}
        className="mb-7"
      />
      {resendTime ? (
        <span className="mb-10 text-sm opacity-80">
          {t('auth.changePassword.resendCodeIn', { time: resendTime })}
        </span>
      ) : (
        <span
          className="mb-10 text-sm underline underline-offset-2 cursor-pointer hover:opacity-90"
          onClick={handleResendCode}
        >
          {t('auth.changePassword.sendNewCode')}
        </span>
      )}
      <Button
        loading={isLoading}
        disabled={!!Object.keys(errors).length}
        className="px-[5.3125rem]"
        type={BUTTON.TYPES.SUBMIT}
      >
        <span>{t('common.next')}</span>
      </Button>
    </form>
  );
};

CodeVerification.propTypes = {
  email: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default CodeVerification;
