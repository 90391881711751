import PropTypes from 'prop-types';

const Sense = ({ height, width, color, ...props }) => (
  <svg
    viewBox="0 0 17 17"
    height={height}
    width={width}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.337 3.403c.092-.075.18-.155.264-.23a7.146 7.146 0 0 1 4.725-1.91 7.044 7.044 0 0 1 3.975 1.058c1.713 1.06 2.801 2.564 3.232 4.473.096.422.126.874.154 1.311.013.202.027.41.047.616.034.35.263.597.568.623.024.001.048 0 .072-.002.355-.01.61-.278.622-.65.05-1.681-.348-3.22-1.182-4.57C14.372 1.783 12.25.413 9.504.05h-.006C7.59-.136 5.888.208 4.29 1.103c-.66.37-1.264.828-1.798 1.361-.285.287-.31.642-.061.91.248.268.606.27.905.029Z"
      fill={color}
    />
    <path
      d="M9.372 3.681c.729.112 1.417.41 2.103.913.331.243.719.208.942-.086a.603.603 0 0 0 .122-.451.72.72 0 0 0-.302-.467C10.69 2.45 8.98 2.082 7.153 2.5a6.074 6.074 0 0 0-3.247 1.9c-.832.945-1.344 2.137-1.48 3.446a3.307 3.307 0 0 1-.013.115 1.587 1.587 0 0 0-.018.296l.004.077c.02.367.04.746.087 1.118a.626.626 0 0 0 .726.548c.334-.054.551-.341.529-.698a3.337 3.337 0 0 0-.023-.24c-.182-1.367.14-2.574.96-3.586C5.879 3.993 7.457 3.389 9.372 3.68Z"
      fill={color}
    />
    <path
      d="M3.077 13.07a7.283 7.283 0 0 1-.106-.123c-.91-1.102-1.46-2.29-1.63-3.532-.165-1.204.02-2.5.55-3.849a5.36 5.36 0 0 1 .342-.672c.063-.115.129-.23.19-.345a.689.689 0 0 0 .007-.661.605.605 0 0 0-.504-.302c-.266-.016-.47.106-.624.372-1.769 3.061-1.735 6.096.1 9.021.202.306.428.596.676.869l.18.21a.694.694 0 0 0 .472.261.622.622 0 0 0 .446-.142c.283-.23.31-.592.066-.91a1.966 1.966 0 0 0-.165-.197ZM12.37 8.607c.11-1.174-.398-2.345-1.359-3.133A3.913 3.913 0 0 0 7.48 4.73c-.394.111-.754.316-1.107.513l-.091.052a.602.602 0 0 0-.29.368.633.633 0 0 0 .078.49c.174.286.526.372.854.211.062-.03.121-.063.178-.1.422-.268.913-.414 1.415-.418 1.328-.035 2.532 1.09 2.575 2.408.024.713-.204 1.331-.695 1.892a.695.695 0 0 0-.193.511.603.603 0 0 0 .226.422.598.598 0 0 0 .338.144c.205.017.422-.073.596-.264.58-.637.9-1.383 1.005-2.35l.001-.001Z"
      fill={color}
    />
    <path
      d="M14.603 8.98c.1-1.146-.085-2.187-.568-3.19a7.575 7.575 0 0 0-.42-.733c-.21-.336-.567-.439-.885-.256-.318.182-.401.549-.198.909.03.054.062.104.094.153l.043.068c.986 1.571.912 3.703-.174 5.184a6.943 6.943 0 0 1-.58.66c-.087.092-.178.186-.265.28a.685.685 0 0 0-.17.667.601.601 0 0 0 .464.405.57.57 0 0 0 .085.014c.244.02.429-.114.566-.237 1.176-1.06 1.852-2.38 2.01-3.921l-.002-.002ZM16.528 10.236a.631.631 0 0 0-.571-.233.63.63 0 0 0-.487.372c-.074.161-.136.328-.196.489-.055.148-.113.301-.174.446a7.432 7.432 0 0 1-1.165 1.8c-.29.34-.287.72.005.97a.554.554 0 0 0 .465.128c.328-.061.788-.356 1.708-2.086a9.648 9.648 0 0 1 .16-.287c.198-.345.422-.737.41-1.166a.69.69 0 0 0-.155-.433ZM6.33 9.738a3.143 3.143 0 0 1-.174-.317c-.29-.644-.297-1.307-.026-2.025a.706.706 0 0 0 0-.554.597.597 0 0 0-.351-.31.635.635 0 0 0-.49.015.69.69 0 0 0-.348.416 4.73 4.73 0 0 0-.24 1.096 3.728 3.728 0 0 0 .662 2.535.708.708 0 0 0 .512.32.617.617 0 0 0 .381-.097c.306-.19.378-.539.184-.89-.036-.065-.074-.126-.11-.189ZM5.821 12.711c-.052-.183-.182-.325-.333-.464a5.862 5.862 0 0 1-.456-.458 5.483 5.483 0 0 1-.728-1.07.685.685 0 0 0-.41-.356.614.614 0 0 0-.475.057.605.605 0 0 0-.298.365.686.686 0 0 0 .09.535l.089.152c.166.308.357.601.572.877.304.358.636.691.992.998.09.08.2.132.32.15a.56.56 0 0 0 .268-.028.605.605 0 0 0 .394-.557.656.656 0 0 0-.025-.2Z"
      fill={color}
    />
  </svg>
);

Sense.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Sense.defaultProps = {
  color: 'var(--colors-gray-0)',
  height: '1.0625rem',
  width: '1.0625rem',
};

export default Sense;
