import { toast } from 'react-toastify';
import { getTimezoneOffset } from 'date-fns-tz';

import config from '@config/index';
import { Toast } from '@components/index';
import { RESOURCE_TYPES } from './consts';

export const showToast = (type, message) => {
  toast(<Toast message={message} type={type} />);
};

export const getProfilePictureUrl = (profilePictureName) =>
  profilePictureName
    ? `${config.resourceApiUrl}/${RESOURCE_TYPES.PROFILE_PICTURE}/${profilePictureName}`
    : null;

// !!! VERY IMPORTANT
// REFLECTIONS AND EXERCISES ARE SCHEDULED BASED ON UTC START DAY WHEN JOURNEY IS STARTED
// OR MOVED TO THE NEXT STEP. THIS IS NEEDED SO USERS CAN ALL SEE EVENTS WITHOUT THEIR TIMEZONE
// OFFSETS (FOR EXAMPLE MORNING REFLECTION IS STATED AT 6 AM FOR EVERYONE). THIS IS ACHIEVED BY
// SUBTRACTING CURRENT TIMESTAMP AND TIMEZONE OFFSETS TO GET SCHEDULED TIME AND ADDING
// CURRENT TIMESTAMP TO TIMEZONE OFFSETS TO GET LOCALIZED TIME
export const timestampToUtc = (date) =>
  Number(date) + getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone, date);

export const timestampToLocal = (date) =>
  Number(date) - getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone, date);
