import PropTypes from 'prop-types';

const Cross = ({ height, width, color, ...props }) => (
  <svg
    viewBox="0 0 16 16"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M15 15 1 1M15 1 1 15" stroke={color} strokeWidth={1.5} strokeLinecap="round" />
  </svg>
);

Cross.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Cross.defaultProps = {
  color: 'var(--colors-tundora)',
  height: '1rem',
  width: '1rem',
};

export default Cross;
