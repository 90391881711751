import PropTypes from 'prop-types';

const Learn = ({ height, width, color, ...props }) => (
  <svg
    viewBox="0 0 17 17"
    height={height}
    width={width}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M14.167 5.844v6.906c0 2.125-1.268 2.833-2.833 2.833H5.667c-1.566 0-2.833-.708-2.833-2.833V5.844c0-2.302 1.267-2.834 2.833-2.834 0 .44.177.836.467 1.127.29.29.687.467 1.127.467H9.74c.878 0 1.594-.715 1.594-1.594 1.565 0 2.833.532 2.833 2.834z"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M11.333 3.01c0 .879-.715 1.594-1.594 1.594H7.26c-.439 0-.836-.177-1.126-.467a1.585 1.585 0 01-.468-1.127c0-.878.716-1.593 1.594-1.593h2.48c.439 0 .835.177 1.126.467.29.29.467.687.467 1.126z"
    />
    <path
      stroke={color}
      strokeLinecap="square"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M5.667 9.208H8.5M5.667 12.042h5.666"
    />
  </svg>
);

Learn.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Learn.defaultProps = {
  color: 'var(--colors-gray-0)',
  height: '1.0625rem',
  width: '1.0625rem',
};

export default Learn;
