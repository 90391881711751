import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useController } from 'react-hook-form';
import { AnimatePresence, motion } from 'framer-motion';

import { AngleArrow } from '@assets/icons';

import DropdownPopup from './DropdownPopup';

const Dropdown = ({
  control,
  name,
  items,
  onChange,
  className,
  label,
  error,
  placeholder,
  disabled,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { field } = useController({
    control,
    name,
  });

  useEffect(() => {
    if (disabled) {
      setIsExpanded(false);
    }
  }, [disabled]);

  const handleItemChanged = (item) => {
    if (!item) {
      return;
    }

    setIsExpanded(false);

    if (item.value === field.value?.value) {
      return;
    }

    field.onChange(item);
    onChange(item);
  };

  return (
    <div className={clsx('relative', className, disabled && 'pointer-events-none')}>
      <div>
        {label && <p className="font-semibold text-sm text-tundora mb-2">{label}</p>}
        <div
          onClick={() => setIsExpanded((prev) => !prev)}
          className={clsx(
            'cursor-pointer flex flex-row justify-between bg-gray-3 items-center px-5 py-3 border-[0.12rem] hover:bg-gray-2 duration-200',
            error ? 'border-roman' : 'border-transparent',
            isExpanded ? 'rounded-t-sm' : 'rounded-sm'
          )}
        >
          <p className={clsx('text-sm', field.value ? 'text-tundora' : 'text-gray-0')}>
            {field.value?.title ?? placeholder}
          </p>
          <motion.div
            initial={false}
            animate={{
              rotate: isExpanded ? 270 : 90,
            }}
            transition={{ duration: 0.3 }}
          >
            <AngleArrow color="var(--colors-tundora)" height="0.9rem" width="0.6rem" />
          </motion.div>
        </div>
      </div>
      <AnimatePresence>
        {isExpanded && (
          <DropdownPopup
            items={items}
            currentValue={field.value?.value}
            onSelect={handleItemChanged}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

Dropdown.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    })
  ).isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.shape({}),
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

Dropdown.defaultProps = {
  label: null,
  placeholder: '',
  onChange: null,
  error: null,
  className: '',
  disabled: false,
};

export default Dropdown;
