import config from '@config/index';
import StorageService from '@services/storage';
import UserContextRef from '@contexts/UserRef';
import HttpService from '../http';

class ApiService {
  constructor(baseUrl) {
    this.http = new HttpService(baseUrl);
    this.internalRefreshToken = this.internalRefreshToken.bind(this);

    this.http.addRequestInterceptor((axiosConfig) => {
      const accessToken = StorageService.getAccessToken();
      if (!accessToken) {
        return axiosConfig;
      }

      axiosConfig.headers = axiosConfig.headers || {}; // eslint-disable-line no-param-reassign
      axiosConfig.headers.Authorization = `Bearer ${accessToken}`; // eslint-disable-line no-param-reassign
      return axiosConfig;
    });

    this.http.addResponseInterceptor(
      (response) => response,
      (error) => {
        const status = error.response ? error.response.status : null;
        const userContextRef = UserContextRef.getInstance();

        if (error.config.url.includes('/session/refresh')) {
          if (userContextRef.isReady) {
            userContextRef.reset();
          }

          return Promise.reject(error);
        }

        if (status === 401) {
          return this.internalRefreshToken()
            .then((accessToken) => {
              if (!accessToken) {
                if (userContextRef.isReady) {
                  userContextRef.reset();
                }

                return Promise.reject(error);
              }

              error.config.headers.Authorization = `Bearer ${accessToken}`; // eslint-disable-line no-param-reassign
              return this.http.axios.request(error.config);
            })
            .catch(() => {
              return Promise.reject(error);
            });
        }

        return Promise.reject(error);
      }
    );
  }

  async internalRefreshToken() {
    const refreshToken = StorageService.getRefreshToken();
    if (!refreshToken) {
      return null;
    }

    const response = await this.http.post(`${config.authApiUrl}/session/refresh`, { refreshToken });
    const tokens = response.data;

    StorageService.setRefreshToken(tokens.refreshToken);
    StorageService.setAccessToken(tokens.accessToken);

    return tokens.accessToken;
  }
}

export default ApiService;
