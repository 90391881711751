import config from '@config/index';

import ApiService from './api';

class AuthApi extends ApiService {
  constructor() {
    if (AuthApi.instance) {
      // eslint-disable-next-line no-constructor-return
      return AuthApi.instance;
    }

    super(config.authApiUrl);
    AuthApi.instance = this;

    this.emailLogin = this.emailLogin.bind(this);
    this.getCurrentUser = this.getCurrentUser.bind(this);
    this.logout = this.logout.bind(this);
    this.invite = this.invite.bind(this);
    this.getAll = this.getAll.bind(this);
    this.requestPasswordReset = this.requestPasswordReset.bind(this);
    this.verifyPasswordResetCode = this.verifyPasswordResetCode.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  async getAll() {
    const response = await this.http.get('/users');
    return response.data;
  }

  async emailLogin({ email, password }) {
    const response = await this.http.post('/login', { email, password });
    return response.data;
  }

  async getCurrentUser() {
    const response = await this.http.get('/session/current');
    return response.data;
  }

  async logout() {
    await this.http.post('/session/logout');
  }

  async invite(data) {
    await this.http.post('/invites', data);
  }

  async requestPasswordReset({ email }) {
    await this.http.post('/passwordReset/request', { email });
  }

  async verifyPasswordResetCode({ email, resetPasswordCode }) {
    await this.http.post('/passwordReset/verifyCode', {
      email,
      resetPasswordCode,
    });
  }

  async resetPassword({ email, password, resetPasswordCode }) {
    const response = await this.http.post('/passwordReset', { email, password, resetPasswordCode });
    return response.data;
  }
}

const authApiInstance = new AuthApi();
export default authApiInstance;
