import Auth from '@pages/Auth';
import { ROOT, AUTH_PATHS } from './consts';

export const AUTH_ROUTES = [
  {
    path: ROOT,
    element: <Auth.SignIn />,
  },
  {
    path: AUTH_PATHS.CHANGE_PASSWORD,
    element: <Auth.ChangePassword />,
  },
];
