import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { LogoLarge } from '@assets/illustrations';

const DimensionError = ({ isLandscapeAvailable }) => {
  const { t } = useTranslation();

  return (
    <div className="h-full w-full flex flex-col items-center justify-center bg-main px-20 pb-20">
      <LogoLarge height="7rem" width="7rem" className="mb-10" />
      <p className="font-medium text-lg text-center">
        {isLandscapeAvailable
          ? t('dimensionError.availableInLandscape')
          : t('dimensionError.notAvailable')}
      </p>
    </div>
  );
};

DimensionError.propTypes = {
  isLandscapeAvailable: PropTypes.bool,
};

DimensionError.defaultProps = {
  isLandscapeAvailable: false,
};

export default DimensionError;
