import Main from '@pages/Main';
import { Clients, Settings } from '@assets/icons';

import { MAIN } from './consts';

export const MAIN_ROUTES = [
  {
    path: MAIN.CLIENTS.PATH,
    isMenu: true,
    name: MAIN.CLIENTS.NAME,
    icon: Clients,
    element: <Main.Clients />,
  },
  {
    path: MAIN.CLIENT_PROFILE.PATH,
    element: <Main.ClientProfile />,
  },
  {
    path: MAIN.SETTINGS.PATH,
    isMenu: true,
    name: MAIN.SETTINGS.NAME,
    icon: Settings,
    element: <Main.Settings />,
  },
];
