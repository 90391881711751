import PropTypes from 'prop-types';

const Exercise = ({ height, width, color, ...props }) => (
  <svg
    viewBox="0 0 23 25"
    height={height}
    width={width}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="a" maskUnits="userSpaceOnUse" x={0} y={0} width={23} height={25} fill={color}>
      <path fill={color} d="M0 0h23v25H0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.781 1.593a2.365 2.365 0 0 1 3.135 0c.954.835 2.232 2.204 2.661 3.836l.103.39-.59.274a6.259 6.259 0 0 0-2.885 3.116l-.144.342-.371-.048a3.146 3.146 0 0 0-.343-.025h-.128v-.485l-.45.19a6.284 6.284 0 0 0-2.887-3.09l-.006-.004-.007-.003a2.599 2.599 0 0 0-.411-.184l-.44-.15.13-.443c.466-1.584 1.704-2.9 2.633-3.716Zm1.71 6.828a7.236 7.236 0 0 1 3.02-3.121c-.438-1.193-1.414-2.255-2.245-2.982l-.002-.002a1.38 1.38 0 0 0-1.83 0l-.002.001c-.8.703-1.738 1.712-2.202 2.86l.112.06a7.264 7.264 0 0 1 3.15 3.184ZM11.329 14.757c.14-.002.281-.013.42-.034l.072.48.446-.202a6.33 6.33 0 0 0 2.82 2.948c.102.054.207.102.313.145l.314.126-.008.335c-.028 1.054-.454 2.024-.995 2.84-.543.816-1.221 1.506-1.8 2.01a2.385 2.385 0 0 1-3.16-.002c-1.107-.974-2.678-2.694-2.793-4.727l-.02-.371.357-.116c.192-.063.378-.142.556-.237l.006-.003a6.4 6.4 0 0 0 2.826-2.915l.133-.277h.512Zm.1.967.102-.005a7.309 7.309 0 0 0 3.172 3.125c-.086.703-.391 1.387-.811 2.018-.48.723-1.093 1.349-1.63 1.815l-.003.003a1.4 1.4 0 0 1-1.855 0v-.001c-1.009-.886-2.191-2.232-2.43-3.71a7.378 7.378 0 0 0 3.456-3.245Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m19.207 11.378.316.255c1.432 1.155 2.088 3.1 2.418 4.402l.002.007.002.006a2.31 2.31 0 0 1-.244 1.64 2.35 2.35 0 0 1-1.283 1.065c-1.352.47-3.52.986-5.343.227l-.324-.135.026-.348v-.025l-.002-.024v-.024a6.153 6.153 0 0 0-1.362-4.02l-.246-.304.248-.303c.174-.213.314-.452.413-.707l.14-.357.382.05a6.423 6.423 0 0 0 4.061-.814l.006-.004.006-.003c.168-.092.327-.2.473-.323l.31-.261Zm-.021 1.258a7.41 7.41 0 0 1-4.599 1.147 3.578 3.578 0 0 1-.183.313 7.115 7.115 0 0 1 1.353 4.1c1.407.444 3.104.071 4.337-.358h.002c.318-.11.585-.331.75-.623a1.35 1.35 0 0 0 .143-.951c-.299-1.177-.825-2.666-1.803-3.628Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.753 14.724a2.672 2.672 0 0 0 1.655-.93l.378.308.384-.3a7.116 7.116 0 0 1 1.56 4.626 1.12 1.12 0 0 1 0 .14l-.05.665-.624-.25a4.453 4.453 0 0 1-.4-.185 7.305 7.305 0 0 1-3.274-3.391l-.274-.586.645-.097Zm2.978 3.003a6.154 6.154 0 0 0-1.003-2.876 3.656 3.656 0 0 1-1.18.672 6.333 6.333 0 0 0 2.183 2.204ZM20.378 5.249a2.35 2.35 0 0 1 1.291 1.059 2.31 2.31 0 0 1 .252 1.641c-.276 1.332-.958 3.282-2.4 4.436l-.31.248-.308-.25a3.68 3.68 0 0 0-.502-.342l-.004-.002a6.384 6.384 0 0 0-4.128-.792l-.316.046-.312-.49.414-.259-.406-.27a6.38 6.38 0 0 0 1.107-3.72V6.55a3.4 3.4 0 0 0-.125-.88l-.11-.394.375-.173c1.842-.853 4.091-.345 5.482.146Zm-5.55 4.97a7.369 7.369 0 0 1 4.357 1.165c.993-.966 1.536-2.47 1.777-3.632l.001-.006a1.35 1.35 0 0 0-.147-.96 1.374 1.374 0 0 0-.756-.62l-.004-.002-.004-.001c-1.244-.44-2.97-.8-4.38-.325.04.23.06.465.063.7a7.337 7.337 0 0 1-.908 3.68ZM3.762 11.38l.31.255c.15.124.312.234.483.328l.005.002.004.003a6.54 6.54 0 0 0 3.817.852l.355-.03.138.325a2.7 2.7 0 0 0 .415.678l-.377.31.384.3a6.153 6.153 0 0 0-1.344 4.019V18.987l-.333.112c-1.741.583-3.737.107-5.02-.34a2.355 2.355 0 0 1-1.29-1.065 2.315 2.315 0 0 1-.247-1.645v-.002c.299-1.314.954-3.258 2.388-4.415l.312-.252Zm4.54 2.721a3.65 3.65 0 0 1-.179-.295 7.527 7.527 0 0 1-4.335-1.172c-.98.963-1.504 2.456-1.77 3.624-.072.327-.02.669.146.961.166.292.435.514.755.623l.004.001c1.147.4 2.704.752 4.047.433A7.114 7.114 0 0 1 8.3 14.101Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m8.906 13.342.384.462a2.686 2.686 0 0 0 1.887.954l-.031.484.442.208a7.375 7.375 0 0 1-3.28 3.356 4.357 4.357 0 0 1-.713.304l-.642.208v-.866a7.117 7.117 0 0 1 1.576-4.642l.377-.468Zm-.964 4.56a6.41 6.41 0 0 0 2.448-2.296 3.665 3.665 0 0 1-1.422-.747 6.152 6.152 0 0 0-1.026 3.043ZM2.56 5.253c1.327-.455 3.44-.962 5.236-.275l.42.16-.127.429c-.095.319-.144.65-.147.982v.005a6.423 6.423 0 0 0 1.094 3.707l.17.252-.432.729-.313-.031a6.511 6.511 0 0 0-3.883.829l-.003.002c-.179.1-.348.215-.507.343l-.308.247-.309-.247c-1.444-1.156-2.1-3.111-2.394-4.43a2.306 2.306 0 0 1 .233-1.63 2.345 2.345 0 0 1 1.265-1.07l.005-.002Zm.324.915a1.368 1.368 0 0 0-.735.624c-.161.29-.21.628-.136.95v.003c.262 1.173.787 2.675 1.775 3.64.098-.066.198-.127.3-.185 1.15-.66 2.454-.999 3.775-.99a7.38 7.38 0 0 1-.9-3.672c.002-.263.027-.525.075-.784-1.367-.352-2.974.01-4.154.414Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.366 9.88c-1.385 0-2.508 1.111-2.508 2.482 0 1.37 1.123 2.482 2.508 2.482s2.508-1.111 2.508-2.482c0-1.37-1.123-2.482-2.508-2.482ZM7.88 12.362c0-1.906 1.56-3.451 3.487-3.451 1.926 0 3.488 1.545 3.488 3.451 0 1.906-1.562 3.452-3.488 3.452S7.88 14.268 7.88 12.362Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.781 1.593a2.365 2.365 0 0 1 3.135 0c.954.835 2.232 2.204 2.661 3.836l.103.39-.59.274a6.259 6.259 0 0 0-2.885 3.116l-.144.342-.371-.048a3.146 3.146 0 0 0-.343-.025h-.128v-.485l-.45.19a6.284 6.284 0 0 0-2.887-3.09l-.006-.004-.007-.003a2.599 2.599 0 0 0-.411-.184l-.44-.15.13-.443c.466-1.584 1.704-2.9 2.633-3.716Zm1.71 6.828a7.236 7.236 0 0 1 3.02-3.121c-.438-1.193-1.414-2.255-2.245-2.982l-.002-.002a1.38 1.38 0 0 0-1.83 0l-.002.001c-.8.703-1.738 1.712-2.202 2.86l.112.06a7.264 7.264 0 0 1 3.15 3.184ZM11.329 14.757c.14-.002.281-.013.42-.034l.072.48.446-.202a6.33 6.33 0 0 0 2.82 2.948c.102.054.207.102.313.145l.314.126-.008.335c-.028 1.054-.454 2.024-.995 2.84-.543.816-1.221 1.506-1.8 2.01a2.385 2.385 0 0 1-3.16-.002c-1.107-.974-2.678-2.694-2.793-4.727l-.02-.371.357-.116c.192-.063.378-.142.556-.237l.006-.003a6.4 6.4 0 0 0 2.826-2.915l.133-.277h.512Zm.1.967.102-.005a7.309 7.309 0 0 0 3.172 3.125c-.086.703-.391 1.387-.811 2.018-.48.723-1.093 1.349-1.63 1.815l-.003.003a1.4 1.4 0 0 1-1.855 0v-.001c-1.009-.886-2.191-2.232-2.43-3.71a7.378 7.378 0 0 0 3.456-3.245Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m19.207 11.378.316.255c1.432 1.155 2.088 3.1 2.418 4.402l.002.007.002.006a2.31 2.31 0 0 1-.244 1.64 2.35 2.35 0 0 1-1.283 1.065c-1.352.47-3.52.986-5.343.227l-.324-.135.026-.348v-.025l-.002-.024v-.024a6.153 6.153 0 0 0-1.362-4.02l-.246-.304.248-.303c.174-.213.314-.452.413-.707l.14-.357.382.05a6.423 6.423 0 0 0 4.061-.814l.006-.004.006-.003c.168-.092.327-.2.473-.323l.31-.261Zm-.021 1.258a7.41 7.41 0 0 1-4.599 1.147 3.578 3.578 0 0 1-.183.313 7.115 7.115 0 0 1 1.353 4.1c1.407.444 3.104.071 4.337-.358h.002c.318-.11.585-.331.75-.623a1.35 1.35 0 0 0 .143-.951c-.299-1.177-.825-2.666-1.803-3.628Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.753 14.724a2.672 2.672 0 0 0 1.655-.93l.378.308.384-.3a7.116 7.116 0 0 1 1.56 4.626 1.12 1.12 0 0 1 0 .14l-.05.665-.624-.25a4.453 4.453 0 0 1-.4-.185 7.305 7.305 0 0 1-3.274-3.391l-.274-.586.645-.097Zm2.978 3.003a6.154 6.154 0 0 0-1.003-2.876 3.656 3.656 0 0 1-1.18.672 6.333 6.333 0 0 0 2.183 2.204ZM20.378 5.249a2.35 2.35 0 0 1 1.291 1.059 2.31 2.31 0 0 1 .252 1.641c-.276 1.332-.958 3.282-2.4 4.436l-.31.248-.308-.25a3.68 3.68 0 0 0-.502-.342l-.004-.002a6.384 6.384 0 0 0-4.128-.792l-.316.046-.312-.49.414-.259-.406-.27a6.38 6.38 0 0 0 1.107-3.72V6.55a3.4 3.4 0 0 0-.125-.88l-.11-.394.375-.173c1.842-.853 4.091-.345 5.482.146Zm-5.55 4.97a7.369 7.369 0 0 1 4.357 1.165c.993-.966 1.536-2.47 1.777-3.632l.001-.006a1.35 1.35 0 0 0-.147-.96 1.374 1.374 0 0 0-.756-.62l-.004-.002-.004-.001c-1.244-.44-2.97-.8-4.38-.325.04.23.06.465.063.7a7.337 7.337 0 0 1-.908 3.68ZM3.762 11.38l.31.255c.15.124.312.234.483.328l.005.002.004.003a6.54 6.54 0 0 0 3.817.852l.355-.03.138.325a2.7 2.7 0 0 0 .415.678l-.377.31.384.3a6.153 6.153 0 0 0-1.344 4.019V18.987l-.333.112c-1.741.583-3.737.107-5.02-.34a2.355 2.355 0 0 1-1.29-1.065 2.315 2.315 0 0 1-.247-1.645v-.002c.299-1.314.954-3.258 2.388-4.415l.312-.252Zm4.54 2.721a3.65 3.65 0 0 1-.179-.295 7.527 7.527 0 0 1-4.335-1.172c-.98.963-1.504 2.456-1.77 3.624-.072.327-.02.669.146.961.166.292.435.514.755.623l.004.001c1.147.4 2.704.752 4.047.433A7.114 7.114 0 0 1 8.3 14.101Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m8.906 13.342.384.462a2.686 2.686 0 0 0 1.887.954l-.031.484.442.208a7.375 7.375 0 0 1-3.28 3.356 4.357 4.357 0 0 1-.713.304l-.642.208v-.866a7.117 7.117 0 0 1 1.576-4.642l.377-.468Zm-.964 4.56a6.41 6.41 0 0 0 2.448-2.296 3.665 3.665 0 0 1-1.422-.747 6.152 6.152 0 0 0-1.026 3.043ZM2.56 5.253c1.327-.455 3.44-.962 5.236-.275l.42.16-.127.429c-.095.319-.144.65-.147.982v.005a6.423 6.423 0 0 0 1.094 3.707l.17.252-.432.729-.313-.031a6.511 6.511 0 0 0-3.883.829l-.003.002c-.179.1-.348.215-.507.343l-.308.247-.309-.247c-1.444-1.156-2.1-3.111-2.394-4.43a2.306 2.306 0 0 1 .233-1.63 2.345 2.345 0 0 1 1.265-1.07l.005-.002Zm.324.915a1.368 1.368 0 0 0-.735.624c-.161.29-.21.628-.136.95v.003c.262 1.173.787 2.675 1.775 3.64.098-.066.198-.127.3-.185 1.15-.66 2.454-.999 3.775-.99a7.38 7.38 0 0 1-.9-3.672c.002-.263.027-.525.075-.784-1.367-.352-2.974.01-4.154.414Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.366 9.88c-1.385 0-2.508 1.111-2.508 2.482 0 1.37 1.123 2.482 2.508 2.482s2.508-1.111 2.508-2.482c0-1.37-1.123-2.482-2.508-2.482ZM7.88 12.362c0-1.906 1.56-3.451 3.487-3.451 1.926 0 3.488 1.545 3.488 3.451 0 1.906-1.562 3.452-3.488 3.452S7.88 14.268 7.88 12.362Z"
      fill={color}
    />
    <path
      clipRule="evenodd"
      d="M9.781 1.593a2.365 2.365 0 0 1 3.135 0c.954.835 2.232 2.204 2.661 3.836l.103.39-.59.274a6.259 6.259 0 0 0-2.885 3.116l-.144.342-.371-.048a3.146 3.146 0 0 0-.343-.025h-.128v-.485l-.45.19a6.284 6.284 0 0 0-2.887-3.09l-.006-.004-.007-.003a2.599 2.599 0 0 0-.411-.184l-.44-.15.13-.443c.466-1.584 1.704-2.9 2.633-3.716Zm1.71 6.828a7.236 7.236 0 0 1 3.02-3.121c-.438-1.193-1.414-2.255-2.245-2.982l-.002-.002a1.38 1.38 0 0 0-1.83 0l-.002.001c-.8.703-1.738 1.712-2.202 2.86l.112.06a7.264 7.264 0 0 1 3.15 3.184ZM11.329 14.757c.14-.002.281-.013.42-.034l.072.48.446-.202a6.33 6.33 0 0 0 2.82 2.948c.102.054.207.102.313.145l.314.126-.008.335c-.028 1.054-.454 2.024-.995 2.84-.543.816-1.221 1.506-1.8 2.01a2.385 2.385 0 0 1-3.16-.002c-1.107-.974-2.678-2.694-2.793-4.727l-.02-.371.357-.116c.192-.063.378-.142.556-.237l.006-.003a6.4 6.4 0 0 0 2.826-2.915l.133-.277h.512Zm.1.967.102-.005a7.309 7.309 0 0 0 3.172 3.125c-.086.703-.391 1.387-.811 2.018-.48.723-1.093 1.349-1.63 1.815l-.003.003a1.4 1.4 0 0 1-1.855 0v-.001c-1.009-.886-2.191-2.232-2.43-3.71a7.378 7.378 0 0 0 3.456-3.245Z"
      stroke={color}
      strokeWidth={0.2}
      strokeMiterlimit={10}
      mask="url(#a)"
    />
    <path
      clipRule="evenodd"
      d="m19.207 11.378.316.255c1.432 1.155 2.088 3.1 2.418 4.402l.002.007.002.006a2.31 2.31 0 0 1-.244 1.64 2.35 2.35 0 0 1-1.283 1.065c-1.352.47-3.52.986-5.343.227l-.324-.135.026-.348v-.025l-.002-.024v-.024a6.153 6.153 0 0 0-1.362-4.02l-.246-.304.248-.303c.174-.213.314-.452.413-.707l.14-.357.382.05a6.423 6.423 0 0 0 4.061-.814l.006-.004.006-.003c.168-.092.327-.2.473-.323l.31-.261Zm-.021 1.258a7.41 7.41 0 0 1-4.599 1.147 3.578 3.578 0 0 1-.183.313 7.115 7.115 0 0 1 1.353 4.1c1.407.444 3.104.071 4.337-.358h.002c.318-.11.585-.331.75-.623a1.35 1.35 0 0 0 .143-.951c-.299-1.177-.825-2.666-1.803-3.628Z"
      stroke={color}
      strokeWidth={0.2}
      strokeMiterlimit={10}
      mask="url(#a)"
    />
    <path
      clipRule="evenodd"
      d="M11.753 14.724a2.672 2.672 0 0 0 1.655-.93l.378.308.384-.3a7.116 7.116 0 0 1 1.56 4.626 1.12 1.12 0 0 1 0 .14l-.05.665-.624-.25a4.453 4.453 0 0 1-.4-.185 7.305 7.305 0 0 1-3.274-3.391l-.274-.586.645-.097Zm2.978 3.003a6.154 6.154 0 0 0-1.003-2.876 3.656 3.656 0 0 1-1.18.672 6.333 6.333 0 0 0 2.183 2.204ZM20.378 5.249a2.35 2.35 0 0 1 1.291 1.059 2.31 2.31 0 0 1 .252 1.641c-.276 1.332-.958 3.282-2.4 4.436l-.31.248-.308-.25a3.68 3.68 0 0 0-.502-.342l-.004-.002a6.384 6.384 0 0 0-4.128-.792l-.316.046-.312-.49.414-.259-.406-.27a6.38 6.38 0 0 0 1.107-3.72V6.55a3.4 3.4 0 0 0-.125-.88l-.11-.394.375-.173c1.842-.853 4.091-.345 5.482.146Zm-5.55 4.97a7.369 7.369 0 0 1 4.357 1.165c.993-.966 1.536-2.47 1.777-3.632l.001-.006a1.35 1.35 0 0 0-.147-.96 1.374 1.374 0 0 0-.756-.62l-.004-.002-.004-.001c-1.244-.44-2.97-.8-4.38-.325.04.23.06.465.063.7a7.337 7.337 0 0 1-.908 3.68ZM3.762 11.38l.31.255c.15.124.312.234.483.328l.005.002.004.003a6.54 6.54 0 0 0 3.817.852l.355-.03.138.325a2.7 2.7 0 0 0 .415.678l-.377.31.384.3a6.153 6.153 0 0 0-1.344 4.019V18.987l-.333.112c-1.741.583-3.737.107-5.02-.34a2.355 2.355 0 0 1-1.29-1.065 2.315 2.315 0 0 1-.247-1.645v-.002c.299-1.314.954-3.258 2.388-4.415l.312-.252Zm4.54 2.721a3.65 3.65 0 0 1-.179-.295 7.527 7.527 0 0 1-4.335-1.172c-.98.963-1.504 2.456-1.77 3.624-.072.327-.02.669.146.961.166.292.435.514.755.623l.004.001c1.147.4 2.704.752 4.047.433A7.114 7.114 0 0 1 8.3 14.101Z"
      stroke={color}
      strokeWidth={0.2}
      strokeMiterlimit={10}
      mask="url(#a)"
    />
    <path
      clipRule="evenodd"
      d="m8.906 13.342.384.462a2.686 2.686 0 0 0 1.887.954l-.031.484.442.208a7.375 7.375 0 0 1-3.28 3.356 4.357 4.357 0 0 1-.713.304l-.642.208v-.866a7.117 7.117 0 0 1 1.576-4.642l.377-.468Zm-.964 4.56a6.41 6.41 0 0 0 2.448-2.296 3.665 3.665 0 0 1-1.422-.747 6.152 6.152 0 0 0-1.026 3.043ZM2.56 5.253c1.327-.455 3.44-.962 5.236-.275l.42.16-.127.429c-.095.319-.144.65-.147.982v.005a6.423 6.423 0 0 0 1.094 3.707l.17.252-.432.729-.313-.031a6.511 6.511 0 0 0-3.883.829l-.003.002c-.179.1-.348.215-.507.343l-.308.247-.309-.247c-1.444-1.156-2.1-3.111-2.394-4.43a2.306 2.306 0 0 1 .233-1.63 2.345 2.345 0 0 1 1.265-1.07l.005-.002Zm.324.915a1.368 1.368 0 0 0-.735.624c-.161.29-.21.628-.136.95v.003c.262 1.173.787 2.675 1.775 3.64.098-.066.198-.127.3-.185 1.15-.66 2.454-.999 3.775-.99a7.38 7.38 0 0 1-.9-3.672c.002-.263.027-.525.075-.784-1.367-.352-2.974.01-4.154.414Z"
      stroke={color}
      strokeWidth={0.2}
      strokeMiterlimit={10}
      mask="url(#a)"
    />
    <path
      clipRule="evenodd"
      d="M11.366 9.88c-1.385 0-2.508 1.111-2.508 2.482 0 1.37 1.123 2.482 2.508 2.482s2.508-1.111 2.508-2.482c0-1.37-1.123-2.482-2.508-2.482ZM7.88 12.362c0-1.906 1.56-3.451 3.487-3.451 1.926 0 3.488 1.545 3.488 3.451 0 1.906-1.562 3.452-3.488 3.452S7.88 14.268 7.88 12.362Z"
      stroke={color}
      strokeWidth={0.2}
      strokeMiterlimit={10}
      mask="url(#a)"
    />
  </svg>
);

Exercise.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Exercise.defaultProps = {
  color: 'var(--colors-gray-0)',
  height: '1.5625rem',
  width: '1.4375rem',
};

export default Exercise;
