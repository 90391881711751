import React, { useContext, useMemo } from 'react';
// eslint-disable-next-line camelcase
import { UNSAFE_RouteContext, Link, useLocation, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ROOT } from '@router/consts';
import { useUser } from '@contexts/User';
import { Logout } from '@assets/icons';
import { LogoLarge } from '@assets/illustrations';

const Navbar = () => {
  const { t } = useTranslation();
  const location = useLocation();

  // !!! Careful
  const router = useContext(UNSAFE_RouteContext);
  const { logout } = useUser();

  const menuRoutes = useMemo(
    () => router.matches[0]?.route.children.filter((route) => route.isMenu) ?? [],
    [router]
  );

  return (
    <nav className="flex flex-col w-32 min-w-[8rem] border-r border-gray-2">
      <div className="h-36 flex items-center justify-center">
        <Link to={ROOT} className="hover:opacity-90">
          <LogoLarge />
        </Link>
      </div>
      <div className="flex-1 flex flex-col px-3 pb-8 pt-2">
        <div className="flex-1 flex flex-col">
          {menuRoutes.map((route) => {
            const isFocused = matchPath(`${route.path}/*`, location.pathname);

            return (
              <Link
                key={route.name}
                to={route.path}
                className="flex flex-col items-center text-center hover:opacity-80 py-2 cursor-pointer mb-4"
              >
                <div className="bg-secondary h-12 w-12 mb-2.5 rounded-sm flex items-center justify-center">
                  <route.icon
                    height="1.25rem"
                    width="1.3125rem"
                    color={isFocused ? 'var(--colors-primary)' : 'var(--colors-tundora)'}
                  />
                </div>
                <span
                  className={clsx(
                    'text-sm font-medium',
                    isFocused ? 'text-primary font-medium' : 'text-tundora font-normal'
                  )}
                >
                  {t(`${route.name}.pageName`)}
                </span>
              </Link>
            );
          })}
        </div>
        <div
          className="self-center bg-secondary h-12 w-12 mt-5 rounded-sm flex items-center justify-center cursor-pointer hover:opacity-80"
          onClick={logout}
        >
          <Logout />
        </div>
      </div>
    </nav>
  );
};

export default React.memo(Navbar);
