import PropTypes from 'prop-types';

const ArrowBoldShort = ({ color, width, height, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 21 19"
      {...props}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.3"
        d="M19 9.5H1.625M9.5 17.375L1.625 9.5 9.5 1.625"
      />
    </svg>
  );
};

ArrowBoldShort.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

ArrowBoldShort.defaultProps = {
  color: 'var(--colors-tundora)',
  width: '1.3125rem',
  height: '1.1875rem',
};

export default ArrowBoldShort;
