import { LoadableFallback } from '@components/index';

import { Logo } from '@assets/illustrations';

const Loading = () => (
  <div className="h-full w-full relative">
    <Logo className="absolute left-8 top-8 z-10" />
    <LoadableFallback />
  </div>
);

export default Loading;
