export const ROOT = '/';
export const ANY = '*';

export const MAIN = {
  CLIENTS: {
    PATH: `${ROOT}clients`,
    NAME: 'clients',
  },
  CLIENT_PROFILE: {
    PATH: `${ROOT}clients/:id`,
    NAME: 'clientProfile',
  },
  SETTINGS: {
    PATH: `${ROOT}settings`,
    NAME: 'settings',
  },
};

export const AUTH_PATHS = {
  CHANGE_PASSWORD: `${ROOT}changePassword`,
};
