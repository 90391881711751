const keys = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
};

class StorageService {
  static getAccessToken() {
    const accessToken = localStorage.getItem(keys.accessToken) || '';
    return accessToken;
  }

  static setAccessToken(accessToken) {
    localStorage.setItem(keys.accessToken, accessToken);
  }

  static removeAccessToken() {
    localStorage.removeItem(keys.accessToken);
  }

  static getRefreshToken() {
    const refreshToken = localStorage.getItem(keys.refreshToken) || '';
    return refreshToken;
  }

  static setRefreshToken(refreshToken) {
    localStorage.setItem(keys.refreshToken, refreshToken);
  }

  static removeRefreshToken() {
    localStorage.removeItem(keys.refreshToken);
  }
}

export default StorageService;
