const en = {
  auth: {
    signIn: {
      title: 'Sign in',
      emailLabel: 'Email Address',
      emailPlaceholder: 'Enter email',
      passwordLabel: 'Password',
      passwordPlaceholder: 'Enter password',
      signInButton: 'Sign in',
      wrongEmailPassword: 'Wrong email or password',
      forgotPassword: 'Forgot your password?',
    },
    changePassword: {
      email: 'Email',
      emailNote: 'Enter your email and  we’ll send you a verification code',
      enterEmail: 'Enter email',
      requestFailed: 'Change password request failed',
      verificationCode: 'Verification code',
      verificationCodeNote: 'We’ve sent you a verification code to {{email}}',
      wrongCode: 'Wrong code',
      enterCode: 'Enter code',
      resendCodeFailed: 'Resend code failed',
      resendCodeIn: 'Resend code in {{time}}',
      sendNewCode: 'Send me a new code',
      createPassword: 'Create new password',
      createPasswordNote:
        'Password needs to be at least 8 characters long. Please use at least one number and both lower and upper case letters',
      resetFailed: 'Password reset failed',
      enterPassword: 'Enter new password',
      passwordConfirmError: 'Passwords do not match',
      confirmPassword: 'Confirm new password',
    },
  },
  clients: {
    pageName: 'My clients',
    title: 'Clients',
    inviteClient: {
      emailPlaceholder: 'Client@gmail.com',
      addClient: 'Add client',
      error: 'Error performing client invite operation',
      success: 'Client {{email}} successfully invited to join platform',
    },
    clientsTable: {
      noClientsTitle: 'No clients found',
      noClientsNote: 'You can invite your clients using input at the top right',
      currentPhase: 'Current phase',
      last24h: 'Last 24h',
      header: {
        name: 'Name/Surname',
        phase: 'Phase',
        engagement: 'Engagement(48h)',
        productivity: 'Productivity',
        mental: 'Mental',
        physical: 'Physical',
        exercises: 'Exercises',
        reflections: 'Reflections',
        assessments: 'Assessments',
      },
      profile: {
        journeyStarted: 'Journey started {{date}}',
      },
      engagement: {
        lastConnected: 'Last connected',
      },
      indicators: {
        averagePercentage: 'avg {{value}}%',
      },
    },
  },
  clientProfile: {
    pageName: 'Profile',
    title: 'Profile',
    age: '{{age}} y.o.',
    differentTimezone: 'Different timezone',
    genders: {
      male: 'Male',
      female: 'Female',
    },
    journeyProgress: {
      title: 'Journey progress',
      titleDay: '- Day {{day}}',
      currentStepDayProgress: 'day {{day}}',
      learn: 'Learn',
      sense: 'Sense',
      grow: 'Grow',
      currentStepDays: '{{day}}d',
      startDate: 'Started {{date}}',
    },
    engagement: {
      title: 'Engagement',
      usedTime: '{{hours}}h',
      connectionTime: 'Last connected time',
      connectionDate: 'Last connected date',
    },
    activityHistory: 'Activity history',
    // report: 'Report',
    learnReport: 'Learn Report',
    reportModal: {
      generateReport: 'Generate Report',
      reportGenerating: 'Report is generating',
      doNotClose: 'Please don’t close the app',
      mental: 'Mental Wellbeing',
      physical: 'Physical Wellbeing',
      productivity: 'Productivity',
      final: 'Final Comments from Coach',
      note: 'Report can be generated at any time. You can share it with your client',
    },
    startNewPhase: 'Start {{phase}}',
    noNewPhase: 'No new phases are planned yet',
    phaseModal: {
      nextPhase: 'Next Phase',
      goals: 'Goals',
      bpm: 'BPM',
      chooseItem: 'Choose',
      athletic: 'Athletic performance',
      productivity: 'Productivity',
      stressBalance: 'Stress balance',
      reliefBeginner: 'Anxiety relief (beginner)',
      reliefAdvanced: 'Anxiety relief (advanced)',
      breathing: 'Breathing',
      nextPhaseSuccess: 'Phase successfully changed',
      nextPhaseError: 'Unable to move to next phase',
      note: 'Remember, once changing journey phase, you can not return the old one',
    },
    exerciseModal: {
      goal: 'Goal',
      result: 'Result',
      learn: 'Learn',
      calm: 'Calm',
      focus: 'Focus',
      energy: 'Energy',
      hrv1: 'HRV-1',
      hrv2: 'HRV-2',
      pulseRate: 'Pulse Rate',
      respiratory: 'Respiratory',
      negative: 'Negative',
      moderatelyNegative: 'Moderately negative',
      neutral: 'Neutral',
      moderatelyPositive: 'Moderately positive',
      positive: 'Positive',
      effectDescription: '{{value}} effect',
    },
    table: {
      indicators: 'Indicators',
      exercises: 'Exercises',
      reflections: 'Reflections',
      assessments: 'Assessments',
    },
    comingSoon: 'Coming Soon',
    assessments: {
      status: {
        complete: 'Complete',
        incomplete: 'Incomplete',
        inProgress: 'In progress',
      },
      header: {
        name: 'Name',
        date: 'Date',
        status: 'Status',
      },
    },
    calendarSection: {
      currentDay: 'Current Day {{dayNum}}',
      day: 'Day {{dayNum}}',
    },
    indicators: {
      day: 'Day',
      currentDay: 'Current day',
      productivity: 'Productivity',
      mental: 'Mental',
      physical: 'Physical',
    },
  },
  settings: {
    pageName: 'Settings',
    title: 'Settings',
    profile: {
      title: 'Profile',
      editProfile: 'Edit profile',
      aboutMe: 'About me',
      editModal: {
        firstName: 'First name',
        lastName: 'Last name',
        editSuccess: 'Profile successfully edited',
        editError: 'Error updating profile',
        imageTypeError: 'You are able to only use images as a profile picture',
        imageUploadError: 'Profile picture uploading failed',
        imageSizeError:
          'File is too big for the upload, the limit is {{sizeLimit}}. Please, choose another one',
      },
    },
    security: {
      title: 'Security',
      password: {
        title: 'Password',
        changePassword: 'Change password',
        changePasswordNote: 'You can change your password at any time',
        passwordChangeSuccess: 'Password successfully changed',
      },
    },
  },
  dimensionError: {
    notAvailable: 'Coach app is available for screens with higher resolution',
    availableInLandscape: 'Coach app is available in landscape mode',
  },
  common: {
    journeyNotStarted: 'Journey is not started yet',
    save: 'Save',
    cancel: 'Cancel',
    next: 'Next',
    seeAll: 'See all',
    firstNameRequiredError: 'First name is required',
    firstNameLengthError: 'Max 250 characters',
    lastNameRequiredError: 'Last name is required',
    lastNameLengthError: 'Max 250 characters',
    emailRequiredError: 'Email is required',
    emailFormatError: 'Incorrect email format',
    passwordRequiredError: 'Password is required',
    passwordLengthError: 'Password must be 8+ characters long',
    passwordFormatError: 'Requires numbers and different case letters',
    codeRequiredError: 'Code is required',
    codeLengthError: 'Code must be 6 characters long',
    codeDigitsError: 'Code must contain only digits',
    changePasswordRequestError: 'Error requesting password change',
    profileError: 'Error processing profile',
    profilesError: 'Error processing profiles',
    exerciseError: 'Error processing exercise',
    journeyError: 'Error processing journey',
    usersError: 'Error processing users',
    engagementError: 'Error processing engagement',
    surveysError: 'Error processing surveys',
    calendarEventsError: 'Error processing calendar events',
    indicatorsError: 'Error processing indicators',
    reportGeneratingError: 'Error generating report',
    enterText: 'Enter text',
    noData: 'No data',
    bpm: 'BPM',
    ms: 'ms',
    bmin: 'b/min',
  },
};

export default en;
