import PropTypes from 'prop-types';

const Star = ({ color, width, height, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 19"
      {...props}
    >
      <path
        fill={color}
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.95"
        d="M10 1l2.781 5.595L19 7.498l-4.5 4.353L15.562 18 10 15.095 4.438 18 5.5 11.85 1 7.499l6.219-.903L10 1z"
      />
    </svg>
  );
};

Star.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Star.defaultProps = {
  color: 'var(--colors-primary)',
  width: '1.25rem',
  height: '1.1875rem',
};

export default Star;
