import PropTypes from 'prop-types';

const Calendar = ({ height, width, color, ...props }) => (
  <svg
    viewBox="0 0 25 25"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.33 2.5v3M16.33 2.5v3M3.83 9.59h17M21.33 9v8.5c0 3-1.5 5-5 5h-8c-3.5 0-5-2-5-5V9c0-3 1.5-5 5-5h8c3.5 0 5 2 5 5Z"
      stroke={color}
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.026 14.2h.009M16.026 17.2h.009M12.326 14.2h.01M12.326 17.2h.01M8.623 14.2h.01M8.623 17.2h.01"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Calendar.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Calendar.defaultProps = {
  color: 'var(--colors-tundora)',
  height: '1.5625rem',
  width: '1.5625rem',
};

export default Calendar;
