import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { AngleArrow } from '@assets/icons';

const PageSequence = ({ Icon, pages, className }) => {
  return (
    <div className={clsx('flex items-center', className)}>
      {Icon && <Icon height="1.25rem" width="1.25rem" className="mr-3" />}
      {pages.map((page, index) => (
        <div key={page.name} className="flex items-center">
          {page.path ? (
            <Link to={page.path} className="font-medium hover:opacity-90">
              {page.name}
            </Link>
          ) : (
            <p className="font-medium">{page.name}</p>
          )}
          {index !== pages.length - 1 && <AngleArrow className="mx-4" />}
        </div>
      ))}
    </div>
  );
};

PageSequence.propTypes = {
  Icon: PropTypes.func,
  className: PropTypes.string,
  pages: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, path: PropTypes.string }))
    .isRequired,
};

PageSequence.defaultProps = {
  Icon: null,
  className: '',
};

export default React.memo(PageSequence);
