import { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import { INPUT } from '@utils/consts';
import { Eye, EyeCrossed } from '@assets/icons';

import styles from './Input.module.scss';

const Input = ({ onChange, value, className, error, label, placeholder, type, style }) => {
  const isPassword = type === INPUT.TYPES.PASSWORD;

  const [textVisible, setTextVisible] = useState(!isPassword);
  const [isFocused, setIsFocused] = useState(false);

  const isActivated = isFocused || !!value;

  const EyeIcon = textVisible ? EyeCrossed : Eye;

  return (
    <div className={clsx('w-full relative', className)}>
      {label && <p className={clsx('text-sm font-semibold mb-2')}>{label}</p>}
      <div
        className={clsx(
          'w-full h-12 rounded-sm text-sm border flex items-center justify-between',
          styles[`container_${style}`],
          isPassword && 'pr-5',
          error ? 'border-roman' : 'border-transparent'
        )}
      >
        <input
          onChange={onChange}
          value={value}
          className={clsx(
            'self-stretch flex-1 pl-5 bg-transparent outline-none',
            styles[style],
            isPassword ? 'mr-5' : 'pr-5'
          )}
          // eslint-disable-next-line no-nested-ternary
          type={isPassword ? (textVisible ? INPUT.TYPES.DEFAULT : INPUT.TYPES.PASSWORD) : type}
          placeholder={placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        {isPassword && (
          <EyeIcon
            className={clsx(
              'cursor-pointer',
              isActivated ? 'hover:opacity-80' : 'opacity-50 hover:opacity-40'
            )}
            onClick={() => setTextVisible((prev) => !prev)}
          />
        )}
      </div>
      <AnimatePresence initial={false} mode="wait">
        <motion.p
          key={error || 'empty'}
          animate={{ opacity: 1, y: 2 }}
          initial={{ opacity: 0, y: -4 }}
          exit={{ opacity: 0, y: -4 }}
          transition={{ duration: 0.2 }}
          className="absolute text-roman text-xs font-light"
        >
          {error}
        </motion.p>
      </AnimatePresence>
    </div>
  );
};

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(Object.values(INPUT.TYPES)),
  style: PropTypes.oneOf(Object.values(INPUT.STYLES)),
};

Input.defaultProps = {
  className: '',
  error: '',
  label: '',
  placeholder: '',
  type: INPUT.TYPES.TEXT,
  style: INPUT.STYLES.LIGHT,
};

Input.displayName = 'Input';

export default Input;
