import { useLayoutEffect, useState } from 'react';

export const useResize = () => {
  const [size, setSize] = useState({ windowWidth: 0, windowHeight: 0 });

  const updateSize = () => {
    setSize({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  };

  useLayoutEffect(() => {
    updateSize();
    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
};
