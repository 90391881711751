import PropTypes from 'prop-types';
import clsx from 'clsx';

import { BUTTON } from '@utils/consts';
import { Loader } from '@components/index';

const Button = ({ className, disabled, loading, type, onClick, children }) => {
  return (
    <button
      disabled={disabled || loading}
      onClick={onClick}
      className={clsx(
        'h-12 flex items-center justify-center text-center bg-primary rounded-sm text-secondary text-sm font-medium relative',
        disabled ? 'opacity-70' : !loading && 'hover:opacity-90',
        className
      )}
      // eslint-disable-next-line react/button-has-type
      type={type}
    >
      {loading && <Loader height="1.25rem" width="1.25rem" className="absolute" />}
      <div className={clsx('flex', loading && 'opacity-0')}>{children}</div>
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(BUTTON.TYPES)),
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  className: '',
  disabled: false,
  loading: false,
  type: BUTTON.TYPES.BUTTON,
  onClick: () => {},
};

export default Button;
