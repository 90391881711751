import PropTypes from 'prop-types';

const Clients = ({ color, height, width, ...props }) => (
  <svg
    viewBox="0 0 21 20"
    height={height}
    width={width}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.367 1.538a3.187 3.187 0 1 0 0 6.374 3.187 3.187 0 0 0 0-6.374ZM2.64 4.725a4.725 4.725 0 1 1 9.45 0 4.725 4.725 0 0 1-9.45 0ZM5.415 13.407a3.873 3.873 0 0 0-3.873 3.873c0 .106.019.18.037.221.015.034.026.04.03.043.528.31 2.046.918 5.758.918 3.71 0 5.228-.608 5.756-.918.005-.003.016-.01.03-.043a.565.565 0 0 0 .038-.221 3.873 3.873 0 0 0-3.873-3.873H5.415ZM.004 17.28a5.412 5.412 0 0 1 5.411-5.412h3.903a5.412 5.412 0 0 1 5.411 5.412c0 .514-.174 1.207-.827 1.59-.86.506-2.69 1.13-6.535 1.13C3.52 20 1.69 19.376.83 18.87c-.653-.383-.827-1.076-.827-1.59ZM13.547 1.887a.77.77 0 0 1 .943-.542c1.697.459 2.876 2.134 2.876 4.04 0 2.013-1.38 3.828-3.314 4.06a.77.77 0 0 1-.184-1.527c1.032-.124 1.96-1.16 1.96-2.533 0-1.303-.798-2.3-1.74-2.554a.77.77 0 0 1-.541-.944ZM15.842 12.49a.77.77 0 0 1 .903-.608 4.03 4.03 0 0 1 3.259 3.957v.534c0 .474-.142 1.148-.76 1.543-.416.266-1.06.549-2.055.75a.77.77 0 1 1-.305-1.508c.849-.172 1.307-.395 1.532-.538l.002-.002a.117.117 0 0 0 .015-.028.59.59 0 0 0 .032-.216v-.535a2.492 2.492 0 0 0-2.015-2.447.77.77 0 0 1-.608-.902Z"
      fill={color}
    />
  </svg>
);

Clients.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Clients.defaultProps = {
  color: 'var(--colors-primary)',
  height: '1.25rem',
  width: '1.3125rem',
};

export default Clients;
