import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';

import { Cross } from '@assets/icons';

const customStyles = {
  content: {
    width: '100%',
    height: '100%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: 0,
    overflow: 'initial',
    background: 'transparent',
  },
  overlay: {
    zIndex: 50,
    background: 'transparent',
    width: '100%',
    height: '100%',
  },
};

const CustomModal = ({ visible, onClose, className, children }) => (
  <AnimatePresence>
    {visible && (
      <Modal isOpen={visible} ariaHideApp={false} style={customStyles} onRequestClose={onClose}>
        <motion.div
          className="bg-black w-full h-full flex justify-center items-center bg-opacity-40"
          onClick={onClose}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
          transition={{ duration: 0.5 }}
        >
          <motion.div
            className="relative z-50"
            onClick={(e) => e.stopPropagation()}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{ duration: 0.3 }}
          >
            <div className={clsx('shadow', className)}>
              <div
                onClick={onClose}
                className="absolute w-[0.88rem] right-[1.5625rem] top-[1.5625rem] cursor-pointer hover:opacity-70"
              >
                <Cross height="0.88rem" width="0.88rem" />
              </div>
              {children}
            </div>
          </motion.div>
        </motion.div>
      </Modal>
    )}
  </AnimatePresence>
);

CustomModal.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

CustomModal.defaultProps = {
  className: '',
};

export default CustomModal;
