import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Row = ({ row, onRowClick, active }) => {
  return (
    <tr
      className={clsx('flex px-5 h-[11.2rem] gap-x-4', active && 'hover:bg-gray-3 cursor-pointer')}
      onClick={() => {
        if (active) {
          onRowClick(row);
        }
      }}
    >
      {row.cells.map((cell, j) => {
        return (
          <td
            className={clsx('flex items-center min-w-0')}
            key={j} // eslint-disable-line react/no-array-index-key
            style={cell.column.style}
            {...cell.getCellProps()}
          >
            {cell.render('Cell')}
          </td>
        );
      })}
    </tr>
  );
};

Row.propTypes = {
  row: PropTypes.shape({ cells: PropTypes.arrayOf(PropTypes.shape({})) }).isRequired,
  onRowClick: PropTypes.func,
  active: PropTypes.bool.isRequired,
};

Row.defaultProps = {
  onRowClick: () => {},
};

export default React.memo(Row);
