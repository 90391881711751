import PropTypes from 'prop-types';

const Eye = ({ color, height, width, ...props }) => (
  <svg
    viewBox="0 0 23 23"
    height={height}
    width={width}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.364 8.769C18.15 5.29 14.91 3.287 11.499 3.287c-1.705 0-3.363.498-4.878 1.428-1.514.94-2.875 2.31-3.986 4.054-.959 1.504-.959 3.948 0 5.453 2.214 3.488 5.453 5.481 8.864 5.481 1.706 0 3.364-.498 4.878-1.428 1.514-.939 2.875-2.31 3.987-4.053.958-1.495.958-3.949 0-5.453Zm-8.865 6.603A3.867 3.867 0 0 1 7.628 11.5a3.867 3.867 0 0 1 3.871-3.872 3.867 3.867 0 0 1 3.872 3.872 3.867 3.867 0 0 1-3.872 3.872Z"
      fill={color}
    />
    <path
      d="M11.5 8.759a2.736 2.736 0 0 0 0 5.472 2.744 2.744 0 0 0 2.742-2.731A2.753 2.753 0 0 0 11.5 8.759Z"
      fill={color}
    />
  </svg>
);

Eye.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Eye.defaultProps = {
  color: 'var(--colors-tundora)',
  height: '1.575rem',
  width: '1.575rem',
};

export default Eye;
