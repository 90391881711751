import PropTypes from 'prop-types';

const Logout = ({ color, height, width, ...props }) => (
  <svg
    viewBox="0 0 24 24"
    height={height}
    width={width}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.898 7.56c.31-3.6 2.16-5.07 6.21-5.07h.13c4.47 0 6.26 1.79 6.26 6.26v6.52c0 4.47-1.79 6.26-6.26 6.26h-.13c-4.02 0-5.87-1.45-6.2-4.99M15.001 12H3.621M5.85 8.65 2.5 12l3.35 3.35"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Logout.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Logout.defaultProps = {
  color: 'var(--colors-gray-0)',
  height: '1.5rem',
  width: '1.5rem',
};

export default Logout;
