import PropTypes from 'prop-types';

const Check = ({ height, width, color, ...props }) => (
  <svg
    viewBox="0 0 11 8"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.59 7.79c.127.134.303.21.487.21h.005a.665.665 0 0 0 .487-.217l6.091-6.667a.667.667 0 1 0-.984-.899L4.07 6.352 1.32 3.425a.667.667 0 0 0-.971.912L3.59 7.79Z"
      fill={color}
    />
  </svg>
);

Check.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Check.defaultProps = {
  color: 'var(--colors-tundora)',
  height: '0.5rem',
  width: '0.688rem',
};

export default Check;
