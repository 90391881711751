import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';

import Input from './Input';

const InputControl = ({ name, control, defaultValue, onChange, ...props }) => {
  const { field } = useController({
    control,
    defaultValue,
    name,
  });

  const handleChange = (e) => {
    field.onChange(e);
    onChange(e);
  };

  return <Input value={field.value} onChange={handleChange} {...props} />;
};

InputControl.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

InputControl.defaultProps = {
  defaultValue: '',
  onChange: () => {},
};

export default InputControl;
