import { Outlet } from 'react-router-dom';

import { Navbar } from './components';

const MainLayout = () => {
  return (
    <div className="h-full max-h-full flex relative bg-main">
      <Navbar />
      <div className="flex flex-1 p-8 overflow-y-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
