import PropTypes from 'prop-types';

import { TOAST_TYPES } from '@utils/consts';

import styles from './Toast.module.scss';

const Toast = ({ message, type }) => {
  return (
    <div className={styles[type]}>
      <span className="font-semibold text-sm">{message}</span>
    </div>
  );
};

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(TOAST_TYPES)).isRequired,
};

export default Toast;
