import { useState, useEffect, useRef, useCallback, useMemo } from 'react';

export const useTimer = () => {
  const [value, setValue] = useState(null);
  const timer = useRef();

  useEffect(
    () => () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    },
    []
  );

  const startTimer = useCallback((initValue, valueInterval, timeInterval = 1000) => {
    setValue(initValue);
    timer.current = setInterval(() => {
      setValue((prev) => prev + valueInterval);
    }, timeInterval);
  }, []);

  const stopTimer = useCallback(() => {
    if (timer.current) {
      clearInterval(timer.current);
    }

    setValue(null);
  }, []);

  return useMemo(
    () => ({
      value,
      startTimer,
      stopTimer,
    }),
    [value, stopTimer, startTimer]
  );
};
