import { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Profile as ProfileIcon } from '@assets/icons';

import styles from './ProfilePicture.module.scss';

const ProfilePicture = ({ url, alt, fallbackIcon }) => {
  const [imgError, setImgError] = useState(false);
  const [isImgLoaded, setIsImgLoaded] = useState(false);

  if (!url || imgError) {
    return fallbackIcon;
  }

  return (
    <img
      className={clsx('h-full w-full object-cover', !isImgLoaded && 'invisible')}
      src={url}
      onError={() => setImgError(true)}
      onLoad={() => setIsImgLoaded(true)}
      alt={alt || 'Profile picture'}
    />
  );
};

const ProfilePictureWrapper = ({ url, alt, fallbackIcon, className }) => {
  return (
    <div
      className={clsx(
        'flex items-center justify-center rounded-full overflow-hidden bg-gray-3',
        styles.wrapper,
        className
      )}
    >
      <ProfilePicture url={url} alt={alt} fallbackIcon={fallbackIcon} />
    </div>
  );
};

ProfilePicture.propTypes = {
  url: PropTypes.string,
  fallbackIcon: PropTypes.node.isRequired,
  alt: PropTypes.string,
};

ProfilePicture.defaultProps = {
  url: undefined,
  alt: undefined,
};

ProfilePictureWrapper.propTypes = {
  url: PropTypes.string,
  fallbackIcon: PropTypes.node,
  alt: PropTypes.string,
  className: PropTypes.string,
};

ProfilePictureWrapper.defaultProps = {
  url: undefined,
  fallbackIcon: <ProfileIcon height="2.6875rem" width="2.125rem" color="var(--colors-gray-1)" />,
  alt: undefined,
  className: '',
};

export default ProfilePictureWrapper;
