import { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { motion, useIsPresent } from 'framer-motion';

const DropdownPopup = ({ items, currentValue, onSelect }) => {
  const isPresent = useIsPresent();

  const [isOpened, setIsOpened] = useState();

  return (
    <motion.div
      initial={{ height: 0 }}
      animate={{ height: 'auto' }}
      exit={{ height: 0 }}
      transition={{ duration: 0.3 }}
      onAnimationComplete={() => setIsOpened(true)}
      className={clsx(
        'absolute z-10 w-full max-h-40 rounded-b-sm bg-gray-3 border-t border-gray-2',
        isPresent && isOpened ? 'overflow-y-auto' : 'overflow-y-hidden'
      )}
    >
      {items?.map((item) => (
        <div
          key={item.value}
          className="flex flex-row justify-between items-center px-5 py-3 hover:bg-gray-2 cursor-pointer"
          onClick={() => onSelect(item)}
        >
          <p
            className={clsx(
              'text-sm text-tundora',
              item.value === currentValue ? 'font-medium' : 'font-base'
            )}
          >
            {item.title}
          </p>
        </div>
      ))}
    </motion.div>
  );
};

DropdownPopup.propTypes = {
  currentValue: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    })
  ).isRequired,
  onSelect: PropTypes.func,
};

DropdownPopup.defaultProps = {
  currentValue: null,
  onSelect: () => {},
};

export default DropdownPopup;
