import PropTypes from 'prop-types';

const Grow = ({ height, width, color, ...props }) => (
  <svg
    viewBox="0 0 17 17"
    height={height}
    width={width}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeWidth="1.5"
      d="M4.874 12.856V11.39M8.5 12.856V9.924M12.127 12.856V8.45M12.127 4.144l-.326.382a13.375 13.375 0 01-6.927 4.279"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M10.051 4.144h2.076v2.068"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M6.375 15.583h4.25c3.541 0 4.958-1.416 4.958-4.958v-4.25c0-3.542-1.417-4.958-4.958-4.958h-4.25c-3.542 0-4.958 1.416-4.958 4.958v4.25c0 3.542 1.416 4.958 4.958 4.958z"
    />
  </svg>
);

Grow.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Grow.defaultProps = {
  color: 'var(--colors-gray-0)',
  height: '1.0625rem',
  width: '1.0625rem',
};

export default Grow;
