import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const TextAreaControl = ({
  name,
  control,
  onChange,
  label,
  maxLength,
  error,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  const { field } = useController({
    control,
    name,
    defaultValue: '',
  });

  const handleTextChange = (event) => {
    field.onChange(event);
    onChange(event);
  };

  return (
    <div className="w-full flex flex-col">
      <div className="flex justify-between mb-2 items-center">
        <label htmlFor={name} className="font-semibold text-sm text-tundora">
          {label}
        </label>
        <p
          className={clsx(
            'text-sm',
            (field.value.length ?? 0) > maxLength ? 'text-roman' : 'text-gray-1'
          )}
        >{`${field.value.length ?? 0}/${maxLength}`}</p>
      </div>
      <textarea
        id={name}
        placeholder={t('common.enterText')}
        className={clsx(
          'bg-gray-3 border-[0.12rem] outline-none resize-none rounded-sm placeholder:text-gray-0 px-5 py-3 text-sm duration-200',
          error ? 'border-roman' : 'border-transparent',
          className
        )}
        value={field.value}
        onChange={handleTextChange}
        {...props}
      />
    </div>
  );
};

TextAreaControl.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  error: PropTypes.shape({}),
};

TextAreaControl.defaultProps = {
  className: '',
  maxLength: 500,
  onChange: null,
  error: null,
};

export default TextAreaControl;
