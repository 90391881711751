import PropTypes from 'prop-types';

const Download = ({ height, width, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 14"
    height={height}
    width={width}
    fill="none"
    {...props}
  >
    <path
      fill="#464646"
      fillRule="evenodd"
      d="M1.63 7.215c.353 0 .64.29.64.645v3.136c0 .554.415 1.008.952 1.054 1.628.138 4.2.326 6.1.326 1.9 0 4.472-.188 6.099-.326.538-.046.952-.5.952-1.054V7.86c0-.356.287-.645.64-.645.355 0 .642.29.642.645v3.136c0 1.214-.914 2.237-2.126 2.34-1.63.138-4.248.33-6.207.33-1.96 0-4.579-.192-6.207-.33-1.213-.103-2.127-1.126-2.127-2.34V7.86c0-.356.287-.645.641-.645z"
      clipRule="evenodd"
    />
    <path
      fill="#464646"
      fillRule="evenodd"
      d="M8.868 8.317c.25.252.657.252.907 0l2.564-2.581a.648.648 0 000-.912.638.638 0 00-.907 0l-1.47 1.479V.979a.643.643 0 00-.64-.646.643.643 0 00-.641.646v5.324l-1.47-1.48a.638.638 0 00-.907 0 .648.648 0 000 .913l2.564 2.58z"
      clipRule="evenodd"
    />
    <path
      stroke="#464646"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.5"
      d="M1.63 7.215c.353 0 .64.29.64.645v3.136c0 .554.415 1.008.952 1.054 1.628.138 4.2.326 6.1.326 1.9 0 4.472-.188 6.099-.326.538-.046.952-.5.952-1.054V7.86c0-.356.287-.645.64-.645.355 0 .642.29.642.645v3.136c0 1.214-.914 2.237-2.126 2.34-1.63.138-4.248.33-6.207.33-1.96 0-4.579-.192-6.207-.33-1.213-.103-2.127-1.126-2.127-2.34V7.86c0-.356.287-.645.641-.645z"
      clipRule="evenodd"
    />
    <path
      stroke="#464646"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="0.5"
      d="M8.868 8.317c.25.252.657.252.907 0l2.564-2.581a.648.648 0 000-.912.638.638 0 00-.907 0l-1.47 1.479V.979a.643.643 0 00-.64-.646.643.643 0 00-.641.646v5.324l-1.47-1.48a.638.638 0 00-.907 0 .648.648 0 000 .913l2.564 2.58z"
      clipRule="evenodd"
    />
  </svg>
);

Download.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Download.defaultProps = {
  color: 'var(--colors-gray-0)',
  height: '0.875rem',
  width: '1.125rem',
};

export default Download;
