import PropTypes from 'prop-types';

const Camera = ({ color, height, width, ...props }) => (
  <svg
    viewBox="0 0 36 32"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.473 0h8.292c1.739.012 3.108.915 3.902 2.48.114.223.25.497.414.835l1.133 2.383c.062.13.194.214.373.214l.373.01a7.68 7.68 0 0 1 7.278 7.667v10.448c0 4.24-3.441 7.677-7.685 7.677H7.687C3.441 31.714 0 28.276 0 24.037V13.589c0-4.24 3.441-7.677 7.603-7.674l.132-.006a.376.376 0 0 0 .29-.21l.866-1.827.679-1.39C10.368.913 11.735.011 13.473 0Zm8.283 2.64h-8.264c-.718.005-1.203.325-1.566 1.038a46.55 46.55 0 0 0-.394.8l-1.117 2.349c-.44.93-1.326 1.57-2.427 1.708l-.3.017a5.04 5.04 0 0 0-5.045 5.037v10.448a5.041 5.041 0 0 0 5.045 5.037h19.865a5.04 5.04 0 0 0 5.043-5.037V13.589c0-2.682-2.105-4.89-4.74-5.029l-.57-.02c-1.06-.094-2-.741-2.46-1.714l-1.232-2.583-.282-.566c-.361-.712-.847-1.032-1.556-1.037ZM10.698 18.197c-.031-3.79 3.103-6.936 6.929-6.931l.356.01A6.932 6.932 0 0 1 24.54 18.2c-.007 3.805-3.11 6.909-6.918 6.913-3.814.002-6.928-3.114-6.925-6.917Zm11.2 0a4.29 4.29 0 0 0-4.273-4.291l-.281.009c-2.23.145-4.021 2.033-4.003 4.272-.001 2.357 1.929 4.288 4.28 4.287a4.298 4.298 0 0 0 4.278-4.278Zm3.832-4.263c.189.075.357.12.527.137l.172.009.174-.009c.405-.04.784-.219 1.073-.51.334-.354.515-.78.515-1.24 0-.24-.05-.478-.147-.697l-.04-.103a1.694 1.694 0 0 0-.335-.46c-.483-.482-1.256-.635-1.906-.378-.219.096-.38.206-.536.342-.206.2-.32.384-.415.603a1.709 1.709 0 0 0-.145.692c0 .452.182.89.497 1.224.175.178.357.296.566.39Z"
      fill={color}
    />
  </svg>
);

Camera.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Camera.defaultProps = {
  color: 'var(--colors-tundora)',
  height: '2rem',
  width: '2.25rem',
};

export default Camera;
