import loadable from '@loadable/component';

import { LoadableFallback } from '@components/index';

const Root = {
  Clients: loadable(() => import('./Clients'), {
    fallback: <LoadableFallback withBorderRadius />,
  }),
  ClientProfile: loadable(() => import('./ClientProfile'), {
    fallback: <LoadableFallback withBorderRadius />,
  }),
  Settings: loadable(() => import('./Settings'), {
    fallback: <LoadableFallback withBorderRadius />,
  }),
};

export default Root;
