export default class UserRef {
  constructor() {
    this.isReady = false;
  }

  static getInstance() {
    if (UserRef.instance) {
      return UserRef.instance;
    }

    UserRef.instance = new UserRef();
    return UserRef.instance;
  }
}
