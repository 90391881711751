import { useLayoutEffect } from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';

import { DimensionError } from '@pages/shared';
import { MEDIA_FONT_SIZE, MEDIA_SCREEN } from '@utils/consts';
import { useResize } from '@hooks/resize';

const ResponsiveFontSizeContainer = ({ children }) => {
  const { windowWidth, windowHeight } = useResize();

  const setFontSize = (val) => {
    document.documentElement.style.fontSize = `${val}px`;
  };

  useLayoutEffect(() => {
    if (windowWidth < MEDIA_SCREEN.SM) {
      setFontSize(MEDIA_FONT_SIZE.XS);
    } else if (windowWidth < MEDIA_SCREEN.MD) {
      setFontSize(MEDIA_FONT_SIZE.SM);
    } else if (windowWidth < MEDIA_SCREEN.LG) {
      setFontSize(MEDIA_FONT_SIZE.MD);
    } else if (windowWidth < MEDIA_SCREEN.XL) {
      setFontSize(MEDIA_FONT_SIZE.LG);
    } else {
      setFontSize(MEDIA_FONT_SIZE.BASE);
    }
  }, [windowWidth]);

  if (windowWidth < MEDIA_SCREEN.XS) {
    return <DimensionError isLandscapeAvailable={isMobile && windowHeight >= MEDIA_SCREEN.XS} />;
  }

  return children;
};

ResponsiveFontSizeContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ResponsiveFontSizeContainer;
