import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';

import AuthService from '@services/api/auth';
import { passwordResetSchema } from '@utils/validators/auth';
import { InputControl, Button } from '@components/index';
import { INPUT, BUTTON, TOAST_TYPES } from '@utils/consts';
import { showToast } from '@utils/helpers';

const FORM_KEYS = {
  PASSWORD: 'password',
  PASSWORD_REPEAT: 'passwordRepeat',
};

const NewPassword = ({ email, code, onSuccess }) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      passwordResetSchema({
        passwordRequiredError: t('common.passwordRequiredError'),
        passwordLengthError: t('common.passwordLengthError'),
        passwordFormatError: t('common.passwordFormatError'),
        passwordRepeatMatchError: t('auth.changePassword.passwordConfirmError'),
      })
    ),
    reValidateMode: 'onSubmit',
    shouldFocusError: false,
  });

  const { mutate: resetPassword, isLoading } = useMutation(AuthService.resetPassword, {
    onError: () => {
      showToast(TOAST_TYPES.ERROR, t('auth.changePassword.resetFailed'));
    },
    onSuccess: (tokens) => {
      onSuccess({ tokens });
    },
  });

  const handleReset = (form) => {
    resetPassword({
      email,
      password: form[FORM_KEYS.PASSWORD],
      resetPasswordCode: code,
    });
  };

  const handleInputChange = (fieldName) => () => {
    clearErrors(fieldName);
  };

  return (
    <form
      onSubmit={handleSubmit(handleReset)}
      className={clsx('flex flex-col items-center', isLoading && 'pointer-events-none')}
    >
      <InputControl
        name={FORM_KEYS.PASSWORD}
        control={control}
        onChange={handleInputChange(FORM_KEYS.PASSWORD)}
        error={errors[FORM_KEYS.PASSWORD]?.message}
        placeholder={t('auth.changePassword.enterPassword')}
        type={INPUT.TYPES.PASSWORD}
        style={INPUT.STYLES.DARK}
        className="mb-7"
      />
      <InputControl
        name={FORM_KEYS.PASSWORD_REPEAT}
        control={control}
        onChange={handleInputChange(FORM_KEYS.PASSWORD_REPEAT)}
        error={errors[FORM_KEYS.PASSWORD_REPEAT]?.message}
        placeholder={t('auth.changePassword.confirmPassword')}
        type={INPUT.TYPES.PASSWORD}
        style={INPUT.STYLES.DARK}
        className="mb-10"
      />
      <Button
        loading={isLoading}
        disabled={!!Object.keys(errors).length}
        className="px-[5.3125rem]"
        type={BUTTON.TYPES.SUBMIT}
      >
        <span>{t('common.next')}</span>
      </Button>
    </form>
  );
};

NewPassword.propTypes = {
  email: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default NewPassword;
