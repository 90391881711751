import PropTypes from 'prop-types';

const Reflection = ({ height, width, color, ...props }) => (
  <svg
    viewBox="0 0 20 21"
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 5.689v10.27c0 2.006 1.233 2.83 2.73 1.837l2.147-1.414c.465-.306 1.242-.338 1.726-.053l4.794 2.776c.484.274 1.26.253 1.726-.053l3.955-2.618c.502-.337.922-1.16.922-1.836V4.328c0-2.006-1.233-2.83-2.73-1.837l-2.147 1.414c-.465.306-1.242.338-1.726.053L7.603 1.192c-.484-.274-1.26-.253-1.726.053L1.922 3.863C1.411 4.2 1 5.023 1 5.689ZM6.727 1.699V15.42M13.271 4.465v14.123"
      stroke={color}
      strokeWidth={1}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Reflection.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Reflection.defaultProps = {
  color: 'var(--colors-gray-0)',
  height: '1.625rem',
  width: '1.625rem',
};

export default Reflection;
