import React from 'react';
import { useSortBy, useTable } from 'react-table';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { AngleArrow } from '@assets/icons';

import { Row } from './components';

const Table = ({ columns, data, onRowClick, emptyComponent, CustomRow, initialSortBy }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      initialState: {
        sortBy: initialSortBy,
      },
    },
    useSortBy
  );

  return (
    <table
      cellSpacing="0"
      cellPadding="0"
      className="flex flex-1 flex-col overflow-hidden"
      {...getTableProps()}
    >
      <thead className="bg-secondary mb-8 rounded-sm" {...headerGroups[0].getHeaderGroupProps()}>
        <tr className="flex pl-5 pr-[1.55rem] py-4 gap-x-4">
          {headerGroups[0].headers.map((column, i) => (
            <th
              key={i} // eslint-disable-line react/no-array-index-key
              className={clsx('flex font-normal text-sm text-gray-0 min-w-0 relative')}
              {...column.getHeaderProps(column.getSortByToggleProps({ style: column.style }))}
            >
              <div className="relative flex items-center">
                {column.render('Header')}
                {column.canSort && (
                  <div
                    className={clsx(
                      '-right-5 absolute transition-transform duration-300',
                      column.isSortedDesc && 'rotate-180'
                    )}
                  >
                    <AngleArrow className="rotate-90 scale-110" color="var(--colors-gray-0)" />
                  </div>
                )}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-secondary rounded-sm flex-1 overflow-y-auto" {...getTableBodyProps()}>
        {rows.length ? (
          rows.map((row) => {
            prepareRow(row);
            const active = !!Object.keys(row.original).length;

            return CustomRow ? (
              <CustomRow key={row.id} row={row} onRowClick={onRowClick} active={active} />
            ) : (
              <Row key={row.id} row={row} onRowClick={onRowClick} active={active} />
            );
          })
        ) : (
          <tr className="flex h-full">
            <td className="flex-1">{emptyComponent}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  initialSortBy: PropTypes.arrayOf(PropTypes.shape({})),
  onRowClick: PropTypes.func,
  emptyComponent: PropTypes.node,
  CustomRow: PropTypes.func,
};

Table.defaultProps = {
  data: [],
  onRowClick: () => {},
  emptyComponent: null,
  CustomRow: null,
  initialSortBy: undefined,
};

export default React.memo(Table);
