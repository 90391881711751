import { Slide, ToastContainer as BaseToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const ToastContainer = () => (
  <BaseToastContainer
    style={{
      top: 0,
      padding: 0,
      width: '100%',
    }}
    toastStyle={{
      padding: 0,
      margin: 0,
      minHeight: 0,
      borderRadius: 0,
    }}
    bodyStyle={{
      padding: 0,
      margin: 0,
    }}
    position="top-center"
    autoClose={3000}
    closeButton={false}
    hideProgressBar
    draggable={false}
    pauseOnFocusLoss={false}
    pauseOnHover
    transition={Slide}
  />
);

export default ToastContainer;
